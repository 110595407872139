import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useRouter } from "next/router";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import { useSelector, useDispatch } from 'react-redux';
import { AddCustomerWishList, AddProductToCart, AlreadyAddedProductToCart } from '../../../helpers/CartHelper';
import Img from "../../../helpers/utils/BgImgRatio";
import myImage from '../../../resources/custom/images/product_main_2.jpg';
import { makeProductShortDescription, replaceWhiteSpacesWithDashSymbolInUrl } from "../../../helpers/ConversionHelper";
import { GetDefaultCurrencySymbol, getLanguageCodeFromSession, TranslatedText, GetLocalizationControlsJsonDataForScreen, GetUserInformation } from "../../../helpers/CommonHelper";
import Config from "../../../helpers/Config"; import GlobalEnums from "../../../helpers/GlobalEnums";

import ProductRatingStars from "./ProductRatingStars";
import rootAction from "../../../stateManagment/actions/rootAction";
import ProductVariants from "../../components/products/ProductVariants";
import { showErrorMsg } from "../../../helpers/ValidationHelper";


const ProductBox = ({ item, hoverEffect, layout, ProductDetailPageForceUpload }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [adminPanelBaseURL, setBaseUrl] = useState(Config['ADMIN_BASE_URL']);
    const [qty, setQuantity] = useState(1);
    const [cartItem, setCartItem] = useState({
        Quantity: 0
    });
    const [ProductId, setProductId] = useState(item.ProductId);
    const [TargetProduct, setTargetProduct] = useState(null);
    const [showProductVariantsPopup, setShowProductVariantsPopup] = useState(false);
    const [productSelectedAttributes, setProductSelectedAttributes] = useState([{ AdditionalPrice: 0 }]);
    const [productAllAttributes, setProductAllAttributes] = useState([]);
    const [filterProductImages, setFilterProductImages] = useState([]);

    const [showPrice, setShowPrice] = useState(false);
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const [onSale, setOnSale] = useState(item.DiscountedPrice != undefined && item.DiscountedPrice != null && item.DiscountedPrice > 0);
    const [DiscountDetails, setDiscountDetails] = useState({
        Title:""
    });
    const customerCompareList = useSelector(state => state.cartReducer.customerCompareList);



    const getSelectedVariant = (ProductAttributeID) => {
        let tempProdAttr = productSelectedAttributes;
        if (ProductAttributeID == -1) return tempProdAttr[0];
        let objIndex = tempProdAttr.findIndex((obj => obj.ProductAttributeID == ProductAttributeID));
        if (tempProdAttr[objIndex] != null) {
            let primaryvalue = tempProdAttr[objIndex];
            return primaryvalue;
        }
        return [];

    }
    const setProductVariantsFromPopup = (PrimaryKeyValue, ProductAttributeID, AdditionalPrice = 0, AttributeDisplayName = "") => {

        let tempProdAttr = [];

        tempProdAttr.push({
            ProductId: item.ProductId,
            ProductAttributeID: ProductAttributeID,
            PrimaryKeyValue: PrimaryKeyValue,
            AdditionalPrice: AdditionalPrice,
            AttributeDisplayName: AttributeDisplayName
        });

        //--Set in product selected attributes
        setProductSelectedAttributes(tempProdAttr);




        //--Set any extra price if associated with this attribute
        let additionalPrice = 0;
        for (let index = 0; index < tempProdAttr.length; index++) {
            let priceData = productAllAttributes?.find(x => x.ProductAttributeID == tempProdAttr[index].ProductAttributeID && x.PrimaryKeyValue == tempProdAttr[index].PrimaryKeyValue);
            if (priceData != null && priceData != undefined && priceData.AdditionalPrice != undefined && priceData.AdditionalPrice > 0) {
                additionalPrice = additionalPrice + priceData.AdditionalPrice;
            }

        }

        //--Set Product images according to product color
        if (ProductAttributeID == Config.PRODUCT_ATTRIBUTE_ENUM['Color']) {
            mappedProductImagesWithColor(PrimaryKeyValue);
        }

        console.log(productSelectedAttributes);
    }

    const mappedProductImagesWithColor = (ColorId) => {
        try {
            const filteredItems = item?.ProductImagesJson.filter(({ ColorID }) => ColorID == ColorId);
            if (filteredItems != null && filteredItems != undefined && filteredItems.length > 0) {
                setFilterProductImages(filteredItems);
            }
        } catch (error) {
            console.error(error.message);

            setFilterProductImages(item?.ProductImagesJson);
        }
    }

    const reloadProductDetail = (_productId, _categoryName, _productName) => {

        let productDetailUrlFromForceReload = `/${getLanguageCodeFromSession()}/product-detail/${_productId}/${replaceWhiteSpacesWithDashSymbolInUrl(_categoryName) ?? "shop"}/${replaceWhiteSpacesWithDashSymbolInUrl(_productName)}`

        if (ProductDetailPageForceUpload != undefined && ProductDetailPageForceUpload != null && ProductDetailPageForceUpload == true && _productId != undefined) {
            navigate(productDetailUrlFromForceReload, { replace: true });
            window.location.reload();
        }
    }

    const HandleCustomerWishList = (ProductID, ProductName, Price, DiscountedPrice, DiscountId, IsDiscountCalculated, CouponCode, defaultImage) => {


        let customerWishList = AddCustomerWishList(ProductID, ProductName, Price, DiscountedPrice, DiscountId, IsDiscountCalculated, CouponCode, 0, '', 0, '', 1, defaultImage);

        //--store in storage
        localStorage.setItem("customerWishList", customerWishList);
        dispatch(rootAction.cartAction.setCustomerWishList(customerWishList));

    }

    const HandleAddToCart = (target) => {

        if (item == undefined || item.ProductId == undefined || item.ProductId < 1) {
            showErrorMsg("Invalid product!");
            return false;
        }

        if (item.TotalAttributes > 0) {
            setShowProductVariantsPopup(true);
            return;
        } else {
            setProductSelectedAttributes([]);
        }
        ConfirmAddToCart(target);

    }

    function updateCartLocal(cartItems) {
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
        dispatch(rootAction.cartAction.setCustomerCart(JSON.stringify(cartItems)));
        dispatch(rootAction.cartAction.SetTotalCartItems((cartItems).length));
    }

    const AddImageToCart = (targetbtn) => {
        if (targetbtn === null) {
            targetbtn = TargetProduct;
        }
        let target = function (e, t) {
            for (; e && e !== document; e = e.parentNode)
                if (e.matches(t))
                    return e;
            return null
        }(targetbtn, ".product-box");
        let shopping_cart = document.getElementsByClassName("icon-shopping-cart")[0]
            , product_image = target.querySelector(".img-fluid")
            , product_left = target.getBoundingClientRect().left
            , product_top = target.getBoundingClientRect().top
            , cart_left = shopping_cart.getBoundingClientRect().left
            , cart_top = shopping_cart.getBoundingClientRect().top
            , new_image = product_image.cloneNode(!0);
        new_image.style = "z-index: 11111; width: 100px; height:100px;opacity:1; position:fixed; top:" + product_top + "px;left:" + product_left + "px;transition: left 1s, top 1s, width 1s, height 1s,opacity 1s cubic-bezier(1, 1, 1, 1);border-radius: 50%; overflow: hidden; box-shadow: 0 21px 36px rgba(0,0,0,0.1)";
        var new_image_doc = document.body.appendChild(new_image);
        setTimeout(() => {
            new_image.style.left = cart_left + "px";
            new_image.style.top = cart_top + "px";
            new_image.style.width = "40px";
            new_image.style.height = "40px";
            new_image.style.opacity = "0";
        }, 200);

        setTimeout(() => {
            new_image_doc.parentNode.removeChild(new_image_doc);
        }, 1e3);

    }
    const ConfirmAddToCart = async (targetbtn) => {

        if (!(item.StockQuantity != null && item.StockQuantity != undefined && item.StockQuantity > 0)) {
            showErrorMsg("Product out of stock!");
            return false;

        }
        let defaultImage = (item?.ProductImagesJson?.length > 0) ? item.ProductImagesJson[0].AttachmentURL : '';
        let cartItems = JSON.parse(AddProductToCart(item.ProductId, qty, item.Price, productSelectedAttributes[0] && productSelectedAttributes[0].ProductAttributeID == undefined ? [] : productSelectedAttributes, item.TotalAttributes, defaultImage));

        if (cartItems != false) {
            AddImageToCart(targetbtn);
        } else {
            return;
        }
        updateQTY();
        updateCartLocal(cartItems);
    }
    let isOutOfStock =item.StockQuantity == null || item.StockQuantity == undefined || item.StockQuantity < 1;        

    const handleSubtractQuantity = () => {


        let cartItems = JSON.parse(localStorage.getItem("cartItems"))
        let qty = cartItems?.find(x => x.ProductId == item.ProductId).Quantity;
        if (qty > 1) {

            let IndexPrd = cartItems.findIndex((obj => obj.ProductId == item.ProductId));
            cartItems[IndexPrd].Quantity = ((qty) - 1);

            updateCartLocal(cartItems);

            updateQTY();
        } else {
            let IndexPrd = cartItems.findIndex((obj => obj.ProductId == item.ProductId));
            cartItems.splice(IndexPrd, 1);
            setCartItem({
                Quantity: 0
            });
            //--store in storage
            updateCartLocal(cartItems);
            //--update in "cartItems"

            updateQTY();
        }
    }

    const handleAddQuantity = (targetbtn) => {

        let cartItems = JSON.parse(localStorage.getItem("cartItems"))

        let qty = cartItems?.find(x => x.ProductId == item.ProductId).Quantity;

        if (item.OrderMaximumQuantity != undefined && item.OrderMaximumQuantity != null && item.OrderMaximumQuantity > 0) {
            if ((qty + 1) > item.OrderMaximumQuantity) {
                showErrorMsg(`Can not add more than ${item.OrderMaximumQuantity} for this product`);
                return false;
            }
        }


        AddImageToCart(targetbtn);
        let IndexPrd = cartItems.findIndex((obj => obj.ProductId == item.ProductId));
        cartItems[IndexPrd].Quantity = ((qty) + 1);

        //--store in storage
        updateCartLocal(cartItems);

        //--update in "cartItems"
        updateQTY();
    }


    const handleCompareList = (ProductId) => {

        try {
            //--check if product already exists
            if (customerCompareList == undefined || customerCompareList?.filter(obj => obj.ProductId == item.ProductId).length == 0) {
                let customerCompareLocal = [];
                customerCompareLocal = customerCompareList == undefined ? [] : customerCompareList;
                customerCompareLocal.push({
                    ProductId: item.ProductId

                });

                console.log(customerCompareLocal);
                dispatch(rootAction.cartAction.setCustomerCompareList(customerCompareLocal));
            }

            navigate('/' + getLanguageCodeFromSession() + '/compare');
        }
        catch (err) {
            console.log(err);
            showErrorMsg("An error occured. Please try again!");

        }


    }
    function updateQTY() {
        const cartProductInfo = AlreadyAddedProductToCart(item.ProductId);
        if (cartProductInfo != false) {
            setCartItem(cartProductInfo[0]);
        } else {
            setCartItem({
                Quantity: 0
            });
        }
    }
    useEffect(() => {
        // declare the data fetching function
        const dataOperationFunc = async () => {
            const userInfo = await GetUserInformation();
            setShowPrice(userInfo.ShowPrice);

            updateQTY();

        

            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["ProductDetail"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                await setLocalizationLabelsArray(arryRespLocalization);
            }
        }
        // call the function
        dataOperationFunc().catch(console.error);
    }, [item]);


    return (<>    <Fragment>
        <div className="product-box">
            <div style={{ border: '1px solid #d7d7d7' }}>
                <div className="product-imgbox">
                    <div className="product-front">
                        {/* <Img src={myImage} className="img-fluid" alt="product" /> */}

                        {(() => {

                            let urlViewDetailImage = `/${getLanguageCodeFromSession()}/product-detail/${item.ProductId}/${replaceWhiteSpacesWithDashSymbolInUrl(item.CategoryName) ?? "shop"}/${replaceWhiteSpacesWithDashSymbolInUrl(item.ProductName)}`;
                            return (
                                <>
                                    <Link to={urlViewDetailImage} onClick={() => reloadProductDetail(item.ProductId, item.CategoryName, item.ProductName)}>

                                        {
                                            item?.ProductImagesJson?.slice(0, 1)?.map((img, imgIdx) =>
                                                <Img src={adminPanelBaseURL + img.AttachmentURL} key={imgIdx} className="img-fluid" alt="product" />


                                            )
                                        }

                                    </Link>
                                </>
                            );
                        })()}



                    </div>
                    <ul className="product-thumb-list">
                        {/* {images.map((pic, i) => (
                            <li className={`grid_thumb_img ${pic.src === imgsrc ? "active" : ""}`} key={i}>
                                <a>
                                    <Img
                                        src={`/images/${pic.src}`}
                                        className="img-fluid"
                                        onMouseEnter={() => imgChange(pic.src)}
                                        alt={pic.src}
                                        onClick={() => {
                                            imgChange(pic.src);
                                        }}
                                    />
                                </a>
                            </li>
                        ))} */}
                    </ul>
                    <div className={`product-icon ${hoverEffect}`}>

                    <Link to={`/${getLanguageCodeFromSession()}/product-detail/${item.ProductId}/${replaceWhiteSpacesWithDashSymbolInUrl(item.CategoryName) ?? "shop"}/${replaceWhiteSpacesWithDashSymbolInUrl(item.ProductName)}`} title="Quick View" >
                            <i className="ti-search" aria-hidden="true"></i>
                        </Link>
                        <a
                            onClick={(e) => {
                                e.preventDefault();
                                HandleCustomerWishList(item.ProductId, item.ProductName, item.Price, item.DiscountedPrice, item.DiscountId, item.IsDiscountCalculated, item.CouponCode, (item?.ProductImagesJson[0]?.AttachmentURL != undefined ? item?.ProductImagesJson[0]?.AttachmentURL : ""))
                            }}
                        >
                            <i className="ti-heart" aria-hidden="true"></i>
                        </a>
                        <a href="#" title="Compare"
                            onClick={(e) => {
                                e.preventDefault();
                                handleCompareList(item.ProductId)
                            }}
                        >
                            <i className="ti-reload" aria-hidden="true"></i>
                        </a>
                    </div>
                    {item?.MarkAsNew && (
                        <div className="new-label1">
                            <div>new</div>
                        </div>
                    )}
                    {onSale && <div className="on-sale1">{item.DiscountTitle}</div>}
                </div>

                <div className="product-detail detail-inline ">
                    <div className="detail-title">
                        <div>

                            <ProductRatingStars Rating={item.Rating == undefined || item.Rating == null ? 5 : item.Rating} />

                            {layout === "list-view" ? (
                                <p>
                                    {makeProductShortDescription(item.ShortDescription, 60)}
                                </p>
                            ) : (
                                ""
                            )}
                            <Link to={`/${getLanguageCodeFromSession()}/product-detail/${item.ProductId}/${replaceWhiteSpacesWithDashSymbolInUrl(item.CategoryName) ?? "shop"}/${replaceWhiteSpacesWithDashSymbolInUrl(item.ProductName)}`}>
                                {item.ProductName}
                                <h6 hidden className="price-title">{item.ProductName}</h6>
                            </Link>

                        </div>
                        <div className="detail-right">
                            {isOutOfStock?<>
                            <div className="out-of-stock">
                                Out of Stock
                            </div>
                            </>:<></>}

                            {showPrice ?
                                <>
                                    {item.DiscountedPrice != undefined && item.DiscountedPrice > 0 ?
                                        <div className="price">
                                            {GetDefaultCurrencySymbol()}{item.DiscountedPrice}
                                            {" "}
                                        </div>
                                        :
                                        <span className="dis-empty-value">
                                          
                                        </span>
                                    }
                                    {'\u00A0'}
                                    <div className={item.DiscountedPrice != undefined && item.DiscountedPrice > 0 ? "check-price" : "price"}>
                                        <div >

                                            {GetDefaultCurrencySymbol()}{item.Price}
                                        </div>
                                    </div>
                                </>

                                : <>{'\u00A0'}</>
                            }

                        </div>
                    </div>

                    <div className="product-buttons">
                        {
                            cartItem.Quantity === 0 ? <>
                            {!isOutOfStock?<>
                                <Link
                                to="#"
                                className="btn btn-normal p-3 w-100"
                                id="lbl_prd_det_addcart"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setTargetProduct(e.target);
                                    HandleAddToCart(e.target);
                                }}
                            >
                                {TranslatedText(LocalizationLabelsArray, "Add to Cart", "lbl_prd_det_add_to_card")}

                            </Link>
                            </>:<>
                            <Link
                                to="#"
                                className="btn btn-normal p-3 w-100 disabled"
                                id="lbl_prd_det_out_of_stock"
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                {TranslatedText(LocalizationLabelsArray, "Out Of Stock", "lbl_prd_det_out_of_stock")}

                            </Link>
                            </>}
                            
                            
                            </> : <>
                                <div
                                    className="d-flex md:h-9 overflow-hidden text-light w-100 w-full"
                                    style={{ background: "var(--theme-color3)" }}
                                >
                                    <button
                                        onClick={handleSubtractQuantity}
                                        className="border-0 cursor-pointer duration-200 focus:outline-0 hover:bg-accent-hover px-3 transition-colors"
                                        style={{ background: "var(--theme-color3)" }}
                                    >
                                        <span className="sr-only">minus</span>
                                        <svg
                                            fill="#ffffff"
                                            viewBox="0 0 24 24"
                                            stroke="#ffffff"
                                            className="h-3 w-3 stroke-2.5"
                                        >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M20 12H4" />
                                        </svg>
                                    </button>
                                    <div
                                        className="align-items-center d-flex flex-1 font-semibold justify-content-center px-3 text-sm"
                                    >
                                        {cartItem.Quantity}
                                    </div>
                                    <button
                                        onClick={(e) => { handleAddQuantity(e.target) }}
                                        className="border-0 cursor-pointer duration-200 focus:outline-0 hover:bg-accent-hover p-2 px-3 transition-colors"
                                        style={{ background: "var(--theme-color3)" }}
                                    >
                                        <span className="sr-only">plus</span>
                                        <svg
                                            fill="#ffffff"
                                            viewBox="0 0 24 24"
                                            className="stroke-2.5 w-4"
                                            stroke="#ffffff"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                            />
                                        </svg>
                                    </button>
                                </div>

                            </>
                        }

                    </div>
                </div>
            </div>

        </div>

    </Fragment>
        <ProductVariants
            ProductId={item.ProductId}
            showProductVariantsPopup={showProductVariantsPopup}
            closeProductVariantPopup={() => {
                setShowProductVariantsPopup(false);
            }}
            confirmProductVariantPopup={() => {
                setShowProductVariantsPopup(false);
                ConfirmAddToCart(null);
            }}
            setProductVariantsFromPopup={setProductVariantsFromPopup}
            productAllAttributes={productAllAttributes}
            setProductAllAttributes={setProductAllAttributes}
            getSelectedVariant={getSelectedVariant}
            ProductImage={item?.ProductImagesJson ? item?.ProductImagesJson[0] : null}
        />


    </>
    );
};
export default ProductBox;
