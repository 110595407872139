import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Config from '../../../helpers/Config';
import rootAction from '../../../stateManagment/actions/rootAction';

import { showErrorMsg, showSuccessMsg } from '../../../helpers/ValidationHelper';
import { TranslatedText} from '../../../helpers/CommonHelper';

import { MakeApiCallAsync } from '../../../helpers/ApiHelpers';


let timerCount = null;
let oldtime = 180;
export default function ContactOtpModal({ show, onHide, doAfterOTP, email,LocalizationLabelsArray }) {
  const dispatch = useDispatch();
  const [Time, setTime] = useState(180);
  let headersEmailSendOTP = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  function substringEmail() {
    if (email.length <= 4) return;
    let first = email.split("@")[0]
    let second = email.split("@")[1]
    if (first.length > 3) {
      let output = first.slice(0, 3) + "X".repeat(first.length - 3) + "@" + second;
      return output;

    } else {
      let output = first + "@" + second;
      return output;
    }
  }

  async function setTimer() {
    if (timerCount != null) clearInterval(timerCount);
    setTime(180);
    oldtime = 180
    timerCount = setInterval(() => {
      oldtime = oldtime - 1;
      setTime(oldtime)
    }, 1000);
  }

  async function sendOTP() {
   

    let paramEmailSendOTP = {
      requestParameters: {
        Email: email,
        OtpType: "Contact Us",
        Registered:true
      },
    };

    //--make api call for data operation
    let responseEmailSendOTP = await MakeApiCallAsync(Config.END_POINT_NAMES['ANY_EMAIL_SEND_OTP'], Config["COMMON_CONTROLLER_SUB_URL"], paramEmailSendOTP, headersEmailSendOTP, "POST", true);

    if (responseEmailSendOTP != null && responseEmailSendOTP.data.statusCode != null && responseEmailSendOTP.data.statusCode == 200 && responseEmailSendOTP.data.message == "Sent Successfully") {
      showSuccessMsg("OTP sent successfully");
    } else {
      showErrorMsg(responseEmailSendOTP.data.errorMessage);
      return false;
    }
  }




  async function sendOTPandResetTime() {
    dispatch(rootAction.commonAction.setLoading(true));

    await sendOTP();
    setTimer();
    dispatch(rootAction.commonAction.setLoading(false));

  }


  async function ValidateOTP() {
    let otp_input = document.querySelector('#otp_input');
    if (otp_input.value.length != 6){
      showErrorMsg("OTP must be 6 digits!"); 
      return;
    }
    dispatch(rootAction.commonAction.setLoading(true));

    let paramEmailValidateOTP = {
      requestParameters: {
        Email: email,
        Otp : otp_input.value,
        Registered:true
      },
    };

    try {
       //--make api call for data operation
    let responseEmailValidateOTP = await MakeApiCallAsync(Config.END_POINT_NAMES['VALIDATE_ANY_EMAIL_SEND_OTP'], Config["COMMON_CONTROLLER_SUB_URL"], paramEmailValidateOTP, headersEmailSendOTP, "POST", true);

    if (responseEmailValidateOTP != null && responseEmailValidateOTP.data.statusCode != null && responseEmailValidateOTP.data.statusCode == 200 && responseEmailValidateOTP.data.message == "OTP verified successfully") {
      await doAfterOTP();
    } else {     
      showErrorMsg(responseEmailValidateOTP.data.errorMessage);
    }
    } catch (error) {
      showErrorMsg("An error occured. Please try again!");
    }
   
    dispatch(rootAction.commonAction.setLoading(false));
  }
  return (
    <Modal
      show={show}
      onShow={sendOTPandResetTime}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          OTP Verification
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <p className='text-center'>
          We have sent you a verification code to your registered email address<br></br>
          <b>{substringEmail()}</b>
        </p>
        <br></br>
        <div className='d-flex align-items-center flex-column'>
          <span className='fs-6'>One-Time Password</span>
          <input className='form-control' id='otp_input' style={{ width: '7rem' }} type='tel' autoComplete='off' />
        </div>
        <br></br>
        <div className='d-flex align-items-center flex-column'>
          {Time < 1 ? (<>
            <div className='text-red'>Code is expired</div>
          </>) : (<>  <div >This code expires in {Time} seconds</div></>)}

        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button id='OtpSendBTN' onClick={sendOTPandResetTime}>Resend OTP</Button>
        <Button onClick={ValidateOTP}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
}
