import React from "react";
import { Container, Row, Col } from "reactstrap";

const DiscountBannerOmg = () => {
  return (
    <section className="discount-banner">
      <Container>
        <Row>
          <Col lg="12">
            <div className="discount-banner-contain">
              <h2>Specializes in the distribution of exotic foods</h2>
              <h1>
               <span>Highly qualitative authentic products</span> <span>wholesalers</span>
              </h1>
              <div className="rounded-contain rounded-inverse">
               <div className="rounded-subcontain">Strong in African, Asian, Middle Eastern and Latin American products</div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default DiscountBannerOmg;
