import React from "react";
import { Row, Col, Container } from "reactstrap";


const SiteBreadcrumb = ({ title, parent, parent_link = "/", title_link = "", space = true }) => {

    return (
        <>

            <div className={`breadcrumb-main ${!space ? "mt-2" : ""}`}>
                <Container>
                    <Row>
                        <Col>
                            <div className="breadcrumb-contain">
                                <div>

                                    <ul>
                                        {(parent_link != "/")?<>
                                        <li>
                                            <a href="/">Home</a>            
                                        </li>
                                        <li>
                                            <i className="fa fa-angle-double-right"></i>
                                        </li>
                                        </>:<></>}

                                        <li>
                                            <a href={parent_link}>{parent}</a>
                                        </li>
                                        <li>
                                            <i className="fa fa-angle-double-right"></i>
                                        </li>
                                        <li>
                                            <a className="active" href={title_link}>{title}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );

}

export default SiteBreadcrumb;