import React, { Fragment, useContext, useEffect, Component, useState } from "react";
import { Container, Row, Col, Media } from "reactstrap";
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Config from "../../../../helpers/Config";
import { MakeApiCallAsync } from "../../../../helpers/ApiHelpers";
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen,replaceLoclizationLabel } from "../../../../helpers/CommonHelper";

import { makeProductShortDescription, replaceWhiteSpacesWithDashSymbolInUrl } from "../../../../helpers/ConversionHelper";
import rootAction from "../../../../stateManagment/actions/rootAction";


const MenuCategory = ({MegaMenuLocalizationLabelsArray}) => {
    const dispatch = useDispatch();
    const [showState, setShowState] = useState(false);


    const { t } = useTranslation();

    let leftMenuState = useSelector(state => state.commonReducer.isLeftMenuSet);

   
    const setLeftMenuManual = (value) => {
        
        dispatch(rootAction.commonAction.setLeftMenu(value));
    }
  

    const [PopularCategoriesList, setPopularCategories] = useState([]);
    const [langCode, setLangCode] = useState('');
    const [adminPanelBaseURL, setBaseUrl] = useState(Config['ADMIN_BASE_URL']);

    const forceLoadCategory =(url) =>{
        window.location.href=url;
     }


    useEffect(() => {
        // declare the data fetching function
        const getPopularCategories = async () => {

            //--Get language code
            let lnCode = getLanguageCodeFromSession();
            await setLangCode(lnCode);

            const headers = {
                // customerid: userData?.UserID,
                // customeremail: userData.EmailAddress,
                Accept: 'application/json',
                'Content-Type': 'application/json',

            }


            const param = {
                requestParameters: {
                    PageNo: 1,
                    PageSize: 30,
                    recordValueJson: "[]",
                },
            };


            const response = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_POPULAR_CATEGORIES'], null, param, headers, "POST", true);
            if (response != null && response.data != null) {
                setPopularCategories(JSON.parse(response.data.data));

            }



        }

        // call the function
        getPopularCategories().catch(console.error);
    }, [])



    return (
        <>
            <Link className="nav-block" to={`/${getLanguageCodeFromSession()}/categories`} >
                <div className="nav-left">
                    <nav className="navbar" data-toggle="collapse" data-target="#navbarToggleExternalContent">
                     
                        <h5 className="mb-0  text-white title-font"> 
                        {MegaMenuLocalizationLabelsArray.length > 0 ?
                        replaceLoclizationLabel(MegaMenuLocalizationLabelsArray, "Shop By Category", "lbl_mgmenu_shop_by_category")
                        :
                        t("shop By Category")
                    }
                        </h5>
                    </nav>
      
                </div>
            </Link>
        </>
    );

}


export default MenuCategory;
