import React, { useContext, useEffect, useState, Component } from "react";

import { Dropdown, Row, Col, Media, Input, DropdownToggle, DropdownMenu, InputGroupText, DropdownItem, InputGroup, ButtonDropdown } from "reactstrap";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';

import ReactDOM from 'react-dom';
import GlobalEnums from '../../../../helpers/GlobalEnums';
import Config from '../../../../helpers/Config';
import { MakeApiCallAsync, MakeApiCallSynchronous } from "../../../../helpers/ApiHelpers";
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, TranslatedText, setLanguageCodeInSession } from '../../../../helpers/CommonHelper';
import { Link } from 'react-router-dom';
import { replaceWhiteSpacesWithDashSymbolInUrl, beterSearchMaker } from "../../../../helpers/ConversionHelper";

import { showInfoMsg } from "../../../../helpers/ValidationHelper";
import MenuUserSection from "./MenuUserSection";
import Wishlist from "./Wishlist";
import MobileSearch from "./MobileSearch";

const langCodeArray = [
  {
    langCode: "en",
    name: "English"
  },
  {
    langCode: "fr",
    name: "French"
  },
  {
    langCode: "ar",
    name: "Arabic"
  }
]


let Controler = new AbortController();
let nodeElm = null;
const TopHeader = ({ LocalizationLabelsArray }) => {

  const { i18n, t } = useTranslation();
  const [openLang, setOpenLang] = useState(false);
  const [SearchTerm, setSearchTerm] = useState("");
  const [adminPanelBaseURL, setBaseUrl] = useState(Config['ADMIN_BASE_URL']);
  const [ProductsList, setProductsList] = useState([]);
  const [ShowSearchBar, setShowSearchBar] = useState(false);
  const [ShowSearched, setShowSearched] = useState(false);

  const [url, setUrl] = useState("");
  const toggleLang = (e) => {
    let lang = e.target.getAttribute("lang");
    if (lang != null) {
      handleLangCodeInSession(lang)
    }
    setOpenLang(!openLang);
  };

  const loginUserDataJson = useSelector(state => state.userReducer.user);
  const loginUser = JSON.parse(loginUserDataJson ?? "{}");

  useEffect(() => {
    const path = window.location.pathname.split("/");
    const urlTemp = path[path.length - 1];
    setUrl(urlTemp);
  }, []);


  const [langCode, setLangCode] = useState('');
  const handleLangCodeInSession = async (value) => {

    await setLanguageCodeInSession(value);
    await setLangCode(value);
    let homeUrl = '/' + value + '/';
    window.location.href = homeUrl;
    // navigate(homeUrl, { replace: true });
  }

  useEffect(() => {
    // declare the data fetching function
    const dataOperationFunc = async () => {
      let lnCode = getLanguageCodeFromSession();
      setLangCode(lnCode);
    }
    // call the function
    dataOperationFunc().catch(console.error);
  }, [])

  const submitSearchForm = (event) => {
    event.preventDefault();

    let categ = 0;
    if (SearchTerm != null && SearchTerm != undefined && SearchTerm.length > 1) {

      let url = "/";
      url = "/" + getLanguageCodeFromSession() + "/all-products/" + categ + "/all-categories?SearchTerm=" + SearchTerm;


      window.location.href = url;

      // navigate(url, { replace: true });
      // window.location.reload();
    } else {
      showInfoMsg('Enter something then search');
    }
  }
  const handleSearch = async () => {
    if (SearchTerm != null && SearchTerm != undefined && SearchTerm.length > 2) {

      Controler = new AbortController();
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
      const param = {
        requestParameters: {
          SearchTerm: beterSearchMaker(SearchTerm),
          PageNo: 1,
          PageSize: 100,

          recordValueJson: "[]",
        },
      };



      const response = await MakeApiCallSynchronous(Config.END_POINT_NAMES['GET_SUGGESTIOON_PRODUCTS'], null, param, headers, "POST", true, Controler);
      if (response != null && response.data != null) {

        await setProductsList(JSON.parse(response.data.data));

        RemoveHandleClick();
        AddHandleClick();
      }

    }
  }
  const AddHandleClick = e => {
    document.addEventListener("click", handleOutsideClick, false);
    setShowSearched(true);
  }
  const RemoveHandleClick = e => {
    document.removeEventListener("click", handleOutsideClick, false);
    setShowSearched(false);
  }
  const handleOutsideClick = e => {
    try {
      RemoveHandleClick(e);
    } catch (error) {
    }
  };

  useEffect(() => {
    Controler.abort();
    setShowSearched(false);
    handleSearch();
  }, [SearchTerm])

  useEffect(() => {

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > 250 && window.innerWidth > 767) {
        setShowSearchBar(true);
      } else {
        setShowSearchBar(false);
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [ShowSearchBar]);


  return (
    <>

      <div className={`top-header ${url === "layout6" ? "top-header-inverse" : ""}`}>
        <div className="custom-container">
          <Row>
            <Col xl="3" md="4" className="d-flex" sm="3">
              <div className="top-header-left">
                <div className="shpping-order">
                  <h6>
                    {TranslatedText(LocalizationLabelsArray, "Free shipping in Belgium", "lbl_thead_free_shipping")}
                  </h6>
                </div>
                <div className="app-link">
                  {loginUser.UserID == null ? (<>
                    <h6>
                      <Link to={`/${getLanguageCodeFromSession()}/signup`} id="lbl_thead_seller"
                        style={{ color: "inherit", textDecoration: "none" }}
                      >
                        {TranslatedText(LocalizationLabelsArray, "Become Customer", "lbl_thead_become_customer")}
                      </Link>
                    </h6>
                  </>) : (<>

                    <h6>
                      <Link to={`/${getLanguageCodeFromSession()}/update-profile`} id="lbl_thead_seller"
                        style={{ color: "inherit", textDecoration: "none" }}
                      >
                        {loginUser.CompanyName}
                      </Link>
                    </h6>
                  </>)}


                </div>
              </div>
            </Col>
            <Col xl="5" md="4" className="d-flex" sm="3">
              {ShowSearchBar ? <>

                <div className="input-block" style={{ width: "100%" }}>
                  <div className="input-box">
                    <form className="big-deal-form pc" onSubmit={submitSearchForm}>
                      <InputGroup>

                        <Input
                          value={SearchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <span className="border-0 h-100" onClick={(e) => submitSearchForm(e)}>
                          <span className="search">
                            <i
                              className="fa fa-search"></i>
                          </span>
                        </span>

                      </InputGroup>
                      {ShowSearched ? <>

                        <div
                          id="search-suggestions"
                          ref={node => {
                            nodeElm = node;
                          }}
                          className="bg-white border-top rounded-3 d-flex flex-column gap-2 p-1 position-absolute"
                        >
                          {ProductsList.length > 0 ? (ProductsList.map((item, i) => {

                            return <a href={`/${getLanguageCodeFromSession()}/product-detail/${item.ProductId}/${replaceWhiteSpacesWithDashSymbolInUrl(item.CategoryName) ?? "shop"}/${replaceWhiteSpacesWithDashSymbolInUrl(item.ProductName)}`} key={i}>
                              <div className="border d-flex gap-1 p-1 rounded-3">
                                <img width={50} height={50} src={adminPanelBaseURL + item.ProductImagesJson[0].AttachmentURL} />
                                <div className="align-items-center d-flex w-100">
                                  <span>{item.ProductName}</span>
                                </div>
                              </div>

                            </a>
                          })) : (<> {TranslatedText(LocalizationLabelsArray, "No Product Found", "lbl_thead_no_prod_found")}</>)}


                        </div>
                      </> : <></>}


                    </form>
                  </div>
                </div>
              </> : <></>}

            </Col>
            <Col xl="4" md="5" sm="6">
              <div className="top-header-right">
                <div className="top-menu-block">
                  <ul>
                  </ul>
                </div>
                <div className="icon-block">
                  <ul>
                    <MenuUserSection />

                    <Wishlist />

                    <MobileSearch LocalizationLabelsArray={LocalizationLabelsArray} />
                  </ul>
                </div>
                <div className="language-block">
                  <div className="language-dropdown">
                  <div id="google_translate_element"></div>
                  <div className="d-none">
                  <Dropdown isOpen={openLang} toggle={toggleLang}>
                      <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={openLang} className="language-dropdown-click">
                        {langCodeArray?.find(x => x.langCode == langCode)?.name}
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </DropdownToggle>
                      <ul className={`language-dropdown-open ${openLang ? "" : "open"}`}>

                        <li lang="en">
                          <a lang="en" onClick={() => handleLangCodeInSession("en")} href="#">English</a>
                        </li>
                        <li lang="fr">
                          <a lang="fr" onClick={() => handleLangCodeInSession("fr")} href="#">French</a>
                        </li>
                      </ul>
                    </Dropdown>
                  </div>
                   
                  </div>

                </div>
              </div>
            </Col>
          </Row>
        </div>

      </div>
    </>
  );

}


export default TopHeader;
