import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Img from "../../../helpers/utils/BgImgRatio";
import { GetDefaultCurrencySymbol, getLanguageCodeFromSession, replaceLoclizationLabel, GetLocalizationControlsJsonDataForScreen } from "../../../helpers/CommonHelper";
import Config from "../../../helpers/Config"; import GlobalEnums from "../../../helpers/GlobalEnums";
let errorImageUrl = Config['ADMIN_BASE_URL'] + "/content/themeContent/global_assets/images/placeholders/placeholder.jpg";

const BrandBox = ({ item }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [adminPanelBaseURL, setBaseUrl] = useState(Config['ADMIN_BASE_URL']);


    return (
        <Fragment>
            <div className="product-box">
                <div className="product-imgbox">
                    <div className="brand-listing">

                        {(() => {

                            let urlViewDetailImage = `/${getLanguageCodeFromSession()}/brand-detail/${item.ManufacturerID}`;
                            return (
                                <>
                                    <a href={urlViewDetailImage}>

                                        <img
                                            src={adminPanelBaseURL + item.AttachmentURL}
                                            className="img-fluid border"
                                            onError={(e) => {
                                                e.target.src = errorImageUrl // some replacement image
                                            }}
                                        />
                                          <div className="brand-name">{item.Name}</div>
                                    </a>
                                </>
                            );
                        })()}



                    </div>

                </div>


            </div>

        </Fragment>
    );
};
export default BrandBox;
