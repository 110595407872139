import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { Row, Col } from "reactstrap";
import BrandBox from "./BrandBox";
import Skeleton from "../products/Skeleton/Skeleton";
import Slider from "react-slick";
import Config from "../../../helpers/Config";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import { GetDefaultCurrencySymbol, getLanguageCodeFromSession, replaceLoclizationLabel, GetLocalizationControlsJsonDataForScreen } from "../../../helpers/CommonHelper";

import GlobalEnums from "../../../helpers/GlobalEnums";
import rootAction from "../../../stateManagment/actions/rootAction";
import { LOADER_DURATION } from "../../../helpers/Constants";

var settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 6,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    adaptiveHeight: true,
    responsive: [

        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
            },
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
            },
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
    ],
};


const Brands = ({ hoverEffect }) => {
    const dispatch = useDispatch();
    const [BrandList, setBrandList] = useState([]);
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const [PaginationInfo, setPaginationInfo] = useState({
        PageNo: 1,
        PageSize: 20,
        TotalRecords: 0
    });

    useEffect(() => {
        // declare the data fetching function
        const dataOperationInUserEffect = async () => {


            const headers = {
                // customerid: userData?.UserID,
                // customeremail: userData.EmailAddress,
                Accept: 'application/json',
                'Content-Type': 'application/json',

            }


            const param = {
                requestParameters: {
                    SearchTerm: "",
                    PageNo: PaginationInfo.PageNo,
                    PageSize: PaginationInfo.PageSize,
                    recordValueJson: "[]",
                },
            };

            const response = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_All_BRANDS'], null, param, headers, "POST", true);
            if (response != null && response.data != null) {
                await setBrandList(JSON.parse(response.data.data));
                console.log(JSON.parse(response.data.data));
            }


            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["PopularProducts"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                await setLocalizationLabelsArray(arryRespLocalization);
            }


        }

        //--start loader
        dispatch(rootAction.commonAction.setLoading(true));

        // call the function
        dataOperationInUserEffect().catch(console.error);

        //--stop loader
        setTimeout(() => {
            dispatch(rootAction.commonAction.setLoading(false));
        }, LOADER_DURATION);

    }, [])

    let brands = `/${getLanguageCodeFromSession()}/brands`;
    return (
        <>
            <div className="title1 pt-0">
                <Link
                    to={brands}
                    className="btn btn-normal p-3 rounded-5"
                    id="lbl_prd_det_addcart"

                >
                    {LocalizationLabelsArray.length > 0 ?
                        replaceLoclizationLabel(LocalizationLabelsArray, "Our Brands", "lbl_prd_det_add_to_request")
                        :
                        "Our Brands"
                    }
                     <i className="fa fa-angle-double-right ms-1"></i>

                </Link>
            </div>
            <div className="product section-pb-space mb--5">
                <div className="custom-container">
                    <Row>
                        <Col className="pe-0">
                            <div className="product-slide-6 ratio_asos no-arrow">

                                <div>
                                    {!BrandList || BrandList.length < 0 ? (
                                        <Skeleton />
                                    ) : (
                                        <Slider {...settings}>
                                            {BrandList &&
                                                BrandList.map((itm, i) => (
                                                    <div key={i}>
                                                        <BrandBox
                                                            item={itm}
                                                        />
                                                    </div>
                                                ))}
                                        </Slider>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};

export default Brands;
