import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, TranslatedText } from '../../../helpers/CommonHelper';

export default function RegisterSuccessModal({ show, onHide, mobile, LocalizationLabelsArray }) {
  const navigate = useNavigate();
  function onClickOK() {
    onHide();

    navigate('/' + getLanguageCodeFromSession() + '/login');

  }
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal-content" style={{ padding: "0rem 0px 1.2rem 0.5rem" }}>
        <div className="modal-body">
          <div
            style={{ display: "flex", justifyContent: "center" }}
            className="m-md-4"
          >
            <img src="/img/tick.svg" width={80} />
          </div>
          <div>
            <h2 style={{ fontWeight: "bold" }} className="mb-2 text-center">
              {TranslatedText(LocalizationLabelsArray, "Successful Registration", "lbl_signup_successful")}
            </h2>
          </div>
          <div className="text-center">
            <span style={{ verticalAlign: "text-bottom" }}>
              {TranslatedText(LocalizationLabelsArray, "For activate your account, contact to the", "lbl_signup_active_1")} <br />
              <span >{TranslatedText(LocalizationLabelsArray, "customer services", "lbl_signup_active_2")} {" "} ({mobile})</span> {" "}{TranslatedText(LocalizationLabelsArray, "or send email for activation account", "lbl_signup_active_3")}
              <a
                rel="norefferer"
                href="mailto:info@orientalexoticsprl.com"
                target="_blank"
              >
                {" "} info@orientalexoticsprl.com
              </a>
            </span>
          </div>

        </div>
        <div className="text-center">
          <button type="button" onClick={onClickOK} className="btn btn-primary" style={{ width: "5rem" }}>
            {TranslatedText(LocalizationLabelsArray, "Ok", "lbl_signup_ok")}
          </button>
        </div>
      </div>

    </Modal>
  );
}
