import React, { Component, useContext, useEffect, useState } from 'react';
import useOutSideClick from '../../../../helpers/utils/outSideClick'
import useMobileSize from '../../../../helpers/utils/isMobile'
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../../helpers/CommonHelper';
import GlobalEnums from '../../../../helpers/GlobalEnums';
import rootAction from '../../../../stateManagment/actions/rootAction';
import { LOADER_DURATION } from '../../../../helpers/Constants';


const MenuBar = ({setMenuResponsive,MegaMenuLocalizationLabelsArray}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [langCode, setLangCode] = useState('');
    const [IsMobileView, setIsMobileView] = useState('');

    const path = window.location.pathname;


    setInterval(() => {
        MobileView();
       
    }, 500);
    function MobileView() {
        if (window.innerWidth < 760) {
            setIsMobileView(true);
        }else{
            setIsMobileView(false);
        }
    }
    return (
        <>

            {path !== "/Layouts/layout3" && (
                <li>
                    <div
                        className="mobile-back text-right"
                        onClick={() => {
                           setMenuResponsive(false);
                            document.body.style.overflow = "visible";
                        }}>
                        
                        {MegaMenuLocalizationLabelsArray.length > 0 ?
                            replaceLoclizationLabel(MegaMenuLocalizationLabelsArray, "Back", "lbl_mgmenu_back")
                            :
                            "Back"
                        } 
                        <i className="fa fa-angle-right ps-2" aria-hidden="true"></i>
                    </div>
                </li>
            )}



            <li>

                <Link to={`/${getLanguageCodeFromSession()}/`} className="dark-menu-item has-submenu" id="lbl_mgmenu_home">
                    {MegaMenuLocalizationLabelsArray.length > 0 ?
                        replaceLoclizationLabel(MegaMenuLocalizationLabelsArray, "Home", "lbl_mgmenu_home")
                        :
                        "Home"
                    }
                </Link>
            </li>
            <li>

                <a href={`/${getLanguageCodeFromSession()}/all-products/0/all-categories`} className="dark-menu-item has-submenu" id="lbl_mgmenu_products">
                    {MegaMenuLocalizationLabelsArray.length > 0 ?
                        replaceLoclizationLabel(MegaMenuLocalizationLabelsArray, "Products", "lbl_mgmenu_products")
                        :
                        "Products"
                    }
                </a>
            </li>
            <li>

                <a href={`/${getLanguageCodeFromSession()}/brands`} className="dark-menu-item has-submenu" id="lbl_mgmenu_brands">
                    {MegaMenuLocalizationLabelsArray.length > 0 ?
                        replaceLoclizationLabel(MegaMenuLocalizationLabelsArray, "Brands", "lbl_mgmenu_brands")
                        :
                        "Brands"
                    }
                </a>
            </li>
            {IsMobileView?<> <li>

<a href={`/${getLanguageCodeFromSession()}/categories`} className="dark-menu-item has-submenu" id="lbl_mgmenu_categories">
    {MegaMenuLocalizationLabelsArray.length > 0 ?
        replaceLoclizationLabel(MegaMenuLocalizationLabelsArray, "Categories", "lbl_mgmenu_categories")
        :
        "Categories"
    }
</a>
</li></>:<></>}
           


            <li>

                <Link to={`/${getLanguageCodeFromSession()}/contact-us`} id="lbl_thead_contct" className="dark-menu-item has-submenu">

                    {MegaMenuLocalizationLabelsArray.length > 0 ?
                        replaceLoclizationLabel(MegaMenuLocalizationLabelsArray, "Contact", "lbl_thead_contct")
                        :
                        "Contact"
                    }
                </Link>
            </li>
            {/* <li>

               <Link to={`/${getLanguageCodeFromSession()}/faq`} id="lbl_thead_faq" className="dark-menu-item has-submenu">

                   {MegaMenuLocalizationLabelsArray.length > 0 ?
                        replaceLoclizationLabel(MegaMenuLocalizationLabelsArray, " FAQ's", "lbl_thead_faq")
                        :
                        "FAQ's"
                    }

                </Link>
            </li>*/}
            <li>

                <Link to={`/${getLanguageCodeFromSession()}/about`} className="dark-menu-item has-submenu" id="lbl_thead_about">
                    {MegaMenuLocalizationLabelsArray.length > 0 ?
                        replaceLoclizationLabel(MegaMenuLocalizationLabelsArray, "About", "lbl_thead_about")
                        :
                        "About"
                    }



                </Link>
            </li>

        </>
    );
};

export default MenuBar;





