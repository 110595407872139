import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import SelectedProductVariants from '../../components/modal/SelectedProductVariants';
import BestFacilities from '../../components/shared/BestFacilities';
import { useSelector, useDispatch } from 'react-redux';
import { LOADER_DURATION } from '../../../helpers/Constants';
import { showErrorMsg, showSuccessMsg, validateAnyFormField } from '../../../helpers/ValidationHelper';
import { MakeApiCallSynchronous, MakeApiCallAsync } from '../../../helpers/ApiHelpers';
import Config from '../../../helpers/Config';
import rootAction from '../../../stateManagment/actions/rootAction';
import { makePriceRoundToTwoPlaces, makeProductShortDescription } from '../../../helpers/ConversionHelper';
import { Helmet } from 'react-helmet';
import { GetDefaultCurrencySymbol, getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, TranslatedText, GetUserInformation } from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';
import { Modal, ModalBody, Button } from "reactstrap";



const Cart = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [adminPanelBaseURL, setadminPanelBaseURL] = useState(Config['ADMIN_BASE_URL']);
    const [CartChanged, setCartChangedStatusCount] = useState(0);
    const [CartSubTotal, setCartSubTotal] = useState(0);
    const [ShippingSubTotal, setShippingSubTotal] = useState(0);
    const [OrderTotal, setOrderTotal] = useState(0);

    const [showPrice, setShowPrice] = useState(false);
    const [cartProductsData, setCartProductsData] = useState(0);
    const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);
    const [productSelectedAttributes, setProductSelectedAttributes] = useState([]);
    const [productAttributePrice, setproductAttributePrice] = useState(0);
    const [ProductInfo, setProductInfo] = useState([]);
    const [showProductVariantsPopup, setShowProductVariantsPopup] = useState(false);
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const loginUserDataJson = useSelector(state => state.userReducer.user);
    const loginUser = JSON.parse(loginUserDataJson ?? "{}");
    const [quantityError, setQuantityError] = useState(false);
    const cartJsonDataSession = useSelector(state => state.cartReducer.cartItems);
    const cartItemsSession = JSON.parse(cartJsonDataSession ?? "[]");

    const styles = {
        popup: {
            display: showProductVariantsPopup ? "block" : "none",
            paddingRight: '16px'
        }
    };

    // if (loginUser == undefined || loginUser.UserID == undefined || loginUser.UserID < 1) {
    //     navigate('/login');
    // }

    const closeProductVariantPopup = () => {
        setShowProductVariantsPopup(!showProductVariantsPopup);
    }


    const handleSubtractQuantity = (ProductId) => {
        let qty = cartProductsData?.find(x => x.ProductId == ProductId).Quantity;
        if (qty > 1) {

            let IndexPrd = cartItemsSession.findIndex((obj => obj.ProductId == ProductId));
            cartItemsSession[IndexPrd].Quantity = ((qty) - 1);

            //--store in storage
            localStorage.setItem("cartItems", JSON.stringify(cartItemsSession));
            //store in redux
            dispatch(rootAction.cartAction.setCustomerCart(JSON.stringify(cartItemsSession)));

            //--update in "cartProductsData"
            let IndexPrdCartProduct = cartProductsData.findIndex((obj => obj.ProductId == ProductId));
            cartProductsData[IndexPrdCartProduct].Quantity = ((qty) - 1);


            //-- set total, sub total, shipping
            setCartTotalSubTotalShippingTotalAfterUpdate();

            //--change the value so that new data uploaded in useEffect
            //setCartChangedStatusCount(CartChanged + 1);
        }
    }

    const handleAddQuantity = (ProductId, OrderMaximumQuantity) => {


        let qty = cartProductsData?.find(x => x.ProductId == ProductId).Quantity;

        if (OrderMaximumQuantity != undefined && OrderMaximumQuantity != null && OrderMaximumQuantity > 0) {
            if ((qty + 1) > OrderMaximumQuantity) {
                showErrorMsg(`Can not add more than ${OrderMaximumQuantity} for this product`);
                return false;
            }
        }



            let IndexPrd = cartItemsSession.findIndex((obj => obj.ProductId == ProductId));
            cartItemsSession[IndexPrd].Quantity = ((qty) + 1);

            //--store in storage
            localStorage.setItem("cartItems", JSON.stringify(cartItemsSession));
            //store in redux
            dispatch(rootAction.cartAction.setCustomerCart(JSON.stringify(cartItemsSession)));

            //--update in "cartProductsData"
            let IndexPrdCartProduct = cartProductsData.findIndex((obj => obj.ProductId == ProductId));
            cartProductsData[IndexPrdCartProduct].Quantity = ((qty) + 1);

            //-- set total, sub total, shipping
            setCartTotalSubTotalShippingTotalAfterUpdate();

            //--change the value so that new data uploaded in useEffect
            // setCartChangedStatusCount(CartChanged + 1);
    }

    const handleQtyUpdate = (ProductId, OrderMaximumQuantity, newValue) => {

        setQuantityError(false);
        newValue = (newValue == null || newValue == undefined || newValue == 0) ? 1 : newValue;
        let qty = cartProductsData?.find(x => x.ProductId == ProductId).Quantity;

        if (OrderMaximumQuantity != undefined && OrderMaximumQuantity != null && OrderMaximumQuantity > 0) {
            if ((newValue) > OrderMaximumQuantity) {
                showErrorMsg(`Can not add more than ${OrderMaximumQuantity} for this product`);
                setQuantityError(true);
                return false;
            }
        }



            let IndexPrd = cartItemsSession.findIndex((obj => obj.ProductId == ProductId));
            cartItemsSession[IndexPrd].Quantity = newValue;

            //--store in storage
            localStorage.setItem("cartItems", JSON.stringify(cartItemsSession));
            //store in redux
            dispatch(rootAction.cartAction.setCustomerCart(JSON.stringify(cartItemsSession)));

            //--update in "cartProductsData"
            let IndexPrdCartProduct = cartProductsData.findIndex((obj => obj.ProductId == ProductId));
            cartProductsData[IndexPrdCartProduct].Quantity = newValue;

            //-- set total, sub total, shipping
            setCartTotalSubTotalShippingTotalAfterUpdate();

            //--change the value so that new data uploaded in useEffect
            setCartChangedStatusCount(CartChanged + 1);
    }


    const handleRemove = (ProductId) => {

        //--remove from session
        let updatedProductsList = cartItemsSession.filter(item => item.ProductId != ProductId);

        //--store in storage
        localStorage.setItem("cartItems", JSON.stringify(updatedProductsList));
        //store in redux
        dispatch(rootAction.cartAction.setCustomerCart(JSON.stringify(updatedProductsList)));
        dispatch(rootAction.cartAction.SetTotalCartItems(updatedProductsList != undefined && updatedProductsList != null ? updatedProductsList.length : (0)));

        //--remove from "cartProductsData"
        let IndexPrdCartData = cartProductsData.findIndex((obj => obj.ProductId == ProductId));
        if (IndexPrdCartData > -1) {
            cartProductsData.splice(IndexPrdCartData, 1);
        }

        //-- set total, sub total, shipping
        setCartTotalSubTotalShippingTotalAfterUpdate();

        //--change the value so that new data uploaded in useEffect
        setCartChangedStatusCount(CartChanged + 1);
    }

    const setCartTotalSubTotalShippingTotalAfterUpdate = () => {

        if (cartProductsData != undefined && cartProductsData != null && cartItemsSession.length > 0) {

            let CartSubTotalDummy = 0;
            let ShippingSubTotalDummuy = 0;
            let OrderTotalDummu = 0;
            for (let i = 0; i < cartProductsData.length; i++) {

                let itemSubTotal = Number((cartProductsData[i].DiscountedPrice != undefined && cartProductsData[i].DiscountedPrice != null && cartProductsData[i].DiscountedPrice > 0 ? cartProductsData[i].DiscountedPrice : cartProductsData[i].Price) * (cartProductsData[i].Quantity ?? 1).toFixed(2));
                cartProductsData[i].ItemSubTotal = itemSubTotal;
                CartSubTotalDummy = CartSubTotalDummy + itemSubTotal;
                ShippingSubTotalDummuy = ShippingSubTotalDummuy + (cartProductsData[i].ShippingCharges ?? 0);
                OrderTotalDummu = OrderTotalDummu + (itemSubTotal + (cartProductsData[i].ShippingCharges ?? 0));

            }

            setTimeout(() => {

                setCartSubTotal(makePriceRoundToTwoPlaces(CartSubTotalDummy));
                setShippingSubTotal(makePriceRoundToTwoPlaces(ShippingSubTotalDummuy));
                setOrderTotal(makePriceRoundToTwoPlaces(OrderTotalDummu));

            }, 500);
        }
    }

    const viewSelectedAttributesOfCartItem = async (ProductId, ProductInfo) => {


        //-- first empty existing data
        setProductSelectedAttributes([]);

        const headersProdAttribte = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }


        const paramProdAttribute = {
            requestParameters: {
                ProductId: ProductId,
                recordValueJson: "[]",
            },
        };


        //--Get product all attributes by product id
        const responseProdAttributes = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_PRODUCT_ALL_ATTRIBUTES_BY_ID'], null, paramProdAttribute, headersProdAttribte, "POST", true);
        if (responseProdAttributes != null && responseProdAttributes.data != null && responseProdAttributes.data.data != null) {

            let ProductAllAttributes = JSON.parse(responseProdAttributes.data.data);
            let productAttr = cartItemsSession.find(x => x.ProductId == ProductId).productSelectedAttributes;

            let extractedAttributes = [];

            if (productAttr != undefined && productAttr != null) {
                for (let index = 0; index < productAttr.length; index++) {
                    let localRowAttr = productAttr[index];
                    let elementGet = ProductAllAttributes.find(x => x.ProductAttributeID == localRowAttr.ProductAttributeID && x.PrimaryKeyValue == localRowAttr.PrimaryKeyValue);
                    extractedAttributes.push(elementGet);
                }
            }
            setProductSelectedAttributes(extractedAttributes);
            setproductAttributePrice(extractedAttributes[0].AdditionalPrice)
            setShowProductVariantsPopup(true);
            setProductInfo(ProductInfo)
            console.log(extractedAttributes);
        }



        //--show the popup
    }



    useEffect(() => {
        // declare the data fetching function
        const dataOperationInUseEffect = async () => {
            const userInfo = await GetUserInformation();
            setShowPrice(userInfo.ShowPrice);

            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }


            //--Get cart data
            const paramCart = {
                requestParameters: {
                    cartJsonData: JSON.stringify(cartItemsSession),
                    recordValueJson: "[]",
                },
            };


            const customerCartResponse = await MakeApiCallAsync(Config.END_POINT_NAMES["GET_CUSTOMER_CART_ITEMS"], Config['COMMON_CONTROLLER_SUB_URL'], paramCart, headers, "POST", true);
            if (customerCartResponse != null && customerCartResponse.data != null) {

                let finalData = JSON.parse(customerCartResponse.data.data);

                if (finalData != null) {
                    setTimeout(() => {
                        setCartProductsData(finalData.productsData);
                        setCartSubTotal(finalData.cartSubTotal);
                        setShippingSubTotal(finalData.shippingSubTotal);
                        setOrderTotal(finalData.orderTotal);

                    }, 500);

                }


            }

            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["Cart"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                await setLocalizationLabelsArray(arryRespLocalization);
            }

        }


        //--start loader
        dispatch(rootAction.commonAction.setLoading(true));


        // call the function
        dataOperationInUseEffect().catch(console.error);

        //--stop loader
        dispatch(rootAction.commonAction.setLoading(false));


    }, [CartChanged])






    return (
        <>
            <Helmet>
                <title>{siteTitle} - cart</title>
                <meta name="description" content={siteTitle + " cart"} />
                <meta name="keywords" content="cart"></meta>
            </Helmet>
            <SiteBreadcrumb title="cart" parent="Home" />
            <section className="cart-section cart-page section-big-py-space mt-3 pt-0 bg-light">
                <div className="custom-container" id="cart-list">
                    <table className={`table cart-table table-responsive-xs ${showPrice?"with-price":"no-price"}` }>
                        <thead>
                            <tr className="table-head">
                                <th scope="col">Product</th>

                                <th scope="col">
                                    {TranslatedText(LocalizationLabelsArray, "Category Name", "lbl_hdr_cart_cat")}
                                </th>

                                <th scope="col">
                                    {TranslatedText(LocalizationLabelsArray, "Variant", "lbl_hdr_cart_var")}
                                </th>
                                {showPrice ? <th scope="col" id="lbl_ordrhis_price">
                                    {TranslatedText(LocalizationLabelsArray, "Unit Price", "lbl_ordrhis_price")}
                                </th> : <></>}
                                <th scope="col">
                                    {TranslatedText(LocalizationLabelsArray, "Quantity", "lbl_hdr_cart_qty")}
                                </th>
                                <th scope="col">action</th>
                                {showPrice ? <th scope="col" id="lbl_ordrhis_itmtotal">
                                    {TranslatedText(LocalizationLabelsArray, "Value", "lbl_ordrhis_itmtotal")}
                                </th> : <></>}
                            </tr>
                        </thead>
                        <tbody>
                            {cartProductsData != undefined && cartProductsData != null && cartProductsData.length > 0 > 0 ? (
                                <>
                                    {cartProductsData.map((item, index) => {

                                        return (

                                            <tr key={`cart-list-${index}`} className="item " >
                                                <td className="d-flex">
                                                    <div className="img-container">
                                                        <img src={item.ProductImagesJson[0]?.AttachmentURL != undefined ? (adminPanelBaseURL + (item.ProductImagesJson[0].AttachmentURL)) : ""} alt="cart" />
                                                    </div>

                                                    <a href={`/${getLanguageCodeFromSession()}/product-detail/${item.ProductId}/category/${item.ProductName}`} >
                                                        <h5>{makeProductShortDescription(item.ProductName, 80)}</h5>
                                                        <span style={{ fontSize: '13px' }}>{item.ProductSelectedAttributes !== undefined && item.ProductSelectedAttributes.length > 0 ? item.ProductSelectedAttributes[0].AttributeDisplayName : ""}</span>
                                                        {showPrice ?
                                                            <h4 className='cart-mobile-price' style={{display:'none'}}>{GetDefaultCurrencySymbol()}{(item.ItemSubTotal)}</h4>
                                                            : <></>}
                                                    </a>

                                                </td>
                                                <td>
                                                    <div>{item.CategoryName}</div>
                                                </td>
                                                <td>
                                                    {item.ProductSelectedAttributes !== undefined && item.ProductSelectedAttributes.length > 0 && item.ProductSelectedAttributes[0].AttributeDisplayName != null ?
                                                        <Link to="#"
                                                            className="order-history-view"
                                                            onClick={() => { viewSelectedAttributesOfCartItem(item.ProductId, item) }}
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}
                                                        >

                                                            <i className="fa fa-eye"></i>
                                                        </Link> : <></>}




                                                </td>
                                                {showPrice ? <td>
                                                    <h4>{GetDefaultCurrencySymbol()}{item.Price}</h4>
                                                </td> : <></>}
                                                <td>
                                                    <div className="align-items-center cart-qty-buttons d-flex justify-content-center ml-auto">
                                                        <button type="button" className="btn remove-cart-btn" onClick={(e) => handleSubtractQuantity(item.ProductId)}>
                                                            -
                                                        </button>
                                                        <input type="number" min={0} disabled={true} readOnly value={item.Quantity} name="quantity"
                                                            onChange={(e) => handleQtyUpdate(item.ProductId, item.OrderMaximumQuantity, e.target.value)}
                                                            className="form-control " />
                                                        <button type="button" className="btn add-cart-btn" onClick={(e) => handleAddQuantity(item.ProductId, item.OrderMaximumQuantity)}>
                                                            +
                                                        </button>

                                                    </div>
                                                </td>


                                                <td>

                                                    <span className="fa fa-trash" onClick={(e) => { e.preventDefault(); handleRemove(item.ProductId) }}
                                                    />
                                                </td>
                                                {showPrice ? <td>
                                                    <h4>{GetDefaultCurrencySymbol()}{(item.ItemSubTotal.toFixed(2))}</h4>
                                                </td> : <><td></td></>}
                                            </tr>

                                        )
                                    })}
                                </>
                            ) : (<></>)}
                        </tbody>
                    </table>
                    {cartProductsData != undefined && cartProductsData != null && cartProductsData.length > 0 > 0 ? (
                        <>

                            <div>
                                <div className="align-items-center cart-buttons gap-2 d-flex justify-content-end w-100">
                                    <Link to={`/${getLanguageCodeFromSession()}/all-products/0/all-categories`} className="btn btn-normal w-100">
                                        {TranslatedText(LocalizationLabelsArray, "Continue Shopping", "lbl_cart_cont_shop")}
                                    </Link>
                                    <Link to={`/${getLanguageCodeFromSession()}/checkout`} className="btn btn-normal w-100 ">
                                        {TranslatedText(LocalizationLabelsArray, "Checkout", "lbl_cart_proc_check")}
                                    </Link>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="col-sm-12">
                            <div>
                                <div className="col-sm-12 empty-cart-cls text-center">
                                    <img src={`static/images/icon-empty-cart.png`} className="img-fluid mb-4" alt="" />
                                    <h3>
                                        <strong>Your Cart is Empty</strong>
                                    </h3>
                                    <h4>Explore more shortlist some items.</h4>
                                    <Link to={`/${getLanguageCodeFromSession()}/all-products/0/all-categories`} className="btn btn-normal mt-2">
                                        {TranslatedText(LocalizationLabelsArray, "Continue Shopping", "lbl_cart_cont_shop")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>

            <BestFacilities />



            <SelectedProductVariants
                showProductVariantsPopup={showProductVariantsPopup}
                closeProductVariantPopup={closeProductVariantPopup}
                productSelectedAttributes={productSelectedAttributes}
                productAttributePrice={productAttributePrice}
                ProductImage={ProductInfo.ProductImagesJson ? ProductInfo.ProductImagesJson[0].AttachmentURL : null}
            />


        </>
    );
};

export default Cart;
