import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Pagination from "react-js-pagination";



const SitePagination = (props) => {

    const [PageRange, setPageRange] = useState(props.PageRange??3);


    const handlePageChange = (pageNumber) => {

        console.log(`active page is ${pageNumber}`);
        // setActivePage(pageNumber);
        props.setCurrentPage(pageNumber);
    }
    function pageRange() {
        if (window.innerWidth < 760) {
            setPageRange(10);
        }
    }

    setInterval(() => {
        pageRange();
    }, 2000)

    return (

        <div style={{ float: "right" }}>
            <Pagination
                activePage={props.CurrentPage}
                itemsCountPerPage={isNaN(props.PageSize) ? props.TotalRecords: props.PageSize}
                totalItemsCount={props.TotalRecords}
                pageRangeDisplayed={PageRange}
                onChange={handlePageChange.bind(this)}
                prevPageText={"<"}
                nextPageText={">"}
                activeClass={"page-item active"}
                activeLinkClass={"page-link"}
                itemClass={"page-item pagination-li-item-custom"}
                innerClass={"pagination"}
                linkClass={"page-link"}
                hideFirstLastPages={true}

            />
        </div>

    );

}

export default SitePagination;

