import React, { Fragment, useContext, useEffect, Component, useState } from "react";

import { Container, Row, Col, Media, Input, DropdownToggle, DropdownMenu, InputGroupText, DropdownItem, InputGroup, ButtonDropdown } from "reactstrap";
import { useSelector, useDispatch } from 'react-redux';
import rootAction from "../../../../stateManagment/actions/rootAction";
import { useParams, Link, useNavigate } from 'react-router-dom';
import MenuUserSection from "./MenuUserSection";
import { getLanguageCodeFromSession, GetUserInformation, TranslatedText, GetDefaultCurrencySymbol } from "../../../../helpers/CommonHelper";

import { showInfoMsg } from "../../../../helpers/ValidationHelper";
import { MakeApiCallAsync, MakeApiCallSynchronous } from "../../../../helpers/ApiHelpers";
import Config from "../../../../helpers/Config";
import GlobalEnums from "../../../../helpers/GlobalEnums";
import { makeProductShortDescription, replaceWhiteSpacesWithDashSymbolInUrl, beterSearchMaker } from "../../../../helpers/ConversionHelper";

let Controler = new AbortController();
let nodeElm = null;
const SearchHeader = ({LocalizationLabelsArray}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showPrice, setShowPrice] = useState(false);
    const [adminPanelBaseURL, setBaseUrl] = useState(Config['ADMIN_BASE_URL']);
    const [ProductsList, setProductsList] = useState([]);
    const [ShowSearched, setShowSearched] = useState(false);
    const [ShowMobileCart, setShowMobileCart] = useState(false);
    const [PopularCategoriesList, setPopularCategories] = useState([]);
    const [LogoImageFromStorage, setLogoImageFromStorage] = useState(useSelector(state => state.commonReducer.websiteLogoInLocalStorage));
    const [searchCategory, setSearchCategory] = useState(0);
    const [categoryText, setCategoryText] = useState("All Category");
    const [SearchTerm, setSearchTerm] = useState("");
    const leftMenuState = useSelector(state => state.commonReducer.isLeftMenuSet);
    const [leftMenu, setLeftMenu] = useState(leftMenuState);
    const [CartPage, setCartPage] = useState(false);
    const totalCartItems = useSelector(state => state.cartReducer.totalCartItems);
    const [CartSubTotal, setCartSubTotal] = useState(0);
    const cartJsonDataSession = useSelector(state => state.cartReducer.cartItems);
    const cartItemsSession = JSON.parse(cartJsonDataSession ?? "[]");

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropDown = () => setDropdownOpen(!dropdownOpen);

    const hangleCategory = (id, text) => {

        setSearchCategory(id ?? 0);
        setCategoryText(text);
    }

    const handleCart = (event) => {
        event.preventDefault();

        if (totalCartItems != null && totalCartItems != null && totalCartItems > 0) {
            navigate('/' + getLanguageCodeFromSession() + '/cart');
        } else {
            showInfoMsg('No item exists in your cart');
        }

    }

    const submitSearchForm = (event) => {
        event.preventDefault();

        let categ = searchCategory ?? 0;
        if (SearchTerm != null && SearchTerm != undefined && SearchTerm.length > 1) {

            let url = "/";
            if (categoryText != undefined && categoryText != "All Category") {
                url = "/" + getLanguageCodeFromSession() + "/all-products/" + categ + "/" + replaceWhiteSpacesWithDashSymbolInUrl(categoryText) + "?SearchTerm=" + SearchTerm;
            } else {
                url = "/" + getLanguageCodeFromSession() + "/all-products/" + categ + "/all-categories?SearchTerm=" + SearchTerm;
            }

            window.location.href = url;

            // navigate(url, { replace: true });
            // window.location.reload();
        } else {
            showInfoMsg('Enter something then search');
        }
    }
    const handleSearch = async () => {
        if (SearchTerm != null && SearchTerm != undefined && SearchTerm.length > 2) {

            Controler = new AbortController();
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
            const param = {
                requestParameters: {
                    SearchTerm: beterSearchMaker(SearchTerm),
                    PageNo: 1,
                    PageSize: 100,

                    recordValueJson: "[]",
                },
            };



            const response = await MakeApiCallSynchronous(Config.END_POINT_NAMES['GET_SUGGESTIOON_PRODUCTS'], null, param, headers, "POST", true, Controler);
            if (response != null && response.data != null) {

                await setProductsList(JSON.parse(response.data.data));

                RemoveHandleClick();
                AddHandleClick();
            }

        }
    }
    const AddHandleClick = e => {
        document.addEventListener("click", handleOutsideClick, false);
        setShowSearched(true);
    }
    const RemoveHandleClick = e => {
        document.removeEventListener("click", handleOutsideClick, false);
        setShowSearched(false);
    }
    const handleOutsideClick = e => {
        try {
            RemoveHandleClick(e);
        } catch (error) {
        }
    };
    useEffect(() => {
        Controler.abort();
        setShowSearched(false);
        handleSearch();
    }, [SearchTerm])
    setInterval(() => {
        cartIcon();
        let searchBox = document.querySelector('#search-suggestions');
        if (searchBox)
            searchBox.style.width = `${document.querySelector('.big-deal-form.pc').scrollWidth + 2}px`;
        if (window.location.href.includes("/cart")) {
            setCartPage(true);
        }
    }, 500);
    function cartIcon() {
        if (window.innerWidth < 760) {
            setShowMobileCart(true);
        } else {
            setShowMobileCart(false);
        }
    }

    // declare the data fetching function
    const dataOperationInUseEffect = async () => {

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
        if (totalCartItems < 1) {
            setCartSubTotal(0);
            return;
        }

        //--Get cart data
        const paramCart = {
            requestParameters: {
                cartJsonData: JSON.stringify(cartItemsSession),
                recordValueJson: "[]",
            },
        };


        const customerCartResponse = await MakeApiCallAsync(Config.END_POINT_NAMES["GET_CUSTOMER_CART_ITEMS"], Config['COMMON_CONTROLLER_SUB_URL'], paramCart, headers, "POST", true);
        if (customerCartResponse != null && customerCartResponse.data != null) {

            let finalData = JSON.parse(customerCartResponse.data.data);

            if (finalData != null) {
                setCartSubTotal(finalData.cartSubTotal);
            }
        }


        let paramLogo = {
            requestParameters: {
                recordValueJson: "[]",
            },
        };

        let WebsiteLogoInLocalStorage = "";
        let logoResponse = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_WEBSITE_LOGO'], null, paramLogo, headers, "POST", true);
        if (logoResponse != null && logoResponse.data != null) {
            console.log(logoResponse.data)

            if (logoResponse.data.data != "") {
                let logoData = JSON.parse(logoResponse.data.data);
                WebsiteLogoInLocalStorage = logoData[0].AppConfigValue;
                dispatch(rootAction.commonAction.setWebsiteLogo(WebsiteLogoInLocalStorage));
                setLogoImageFromStorage(WebsiteLogoInLocalStorage);
            }


        }

    }

    useEffect(() => {
        dataOperationInUseEffect();
    }, [totalCartItems,cartJsonDataSession])

    useEffect(() => {

        // declare the data fetching function
        const getPopularCategories = async () => {
            const userInfo = await GetUserInformation();
            setShowPrice(userInfo.ShowPrice);

            //--Get language code
            let lnCode = getLanguageCodeFromSession();


            const headers = {
                // customerid: userData?.UserID,
                // customeremail: userData.EmailAddress,
                Accept: 'application/json',
                'Content-Type': 'application/json',

            }


            const param = {
                requestParameters: {
                    PageNo: 1,
                    PageSize: 30,
                    recordValueJson: "[]",
                },
            };


            const response = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_POPULAR_CATEGORIES'], null, param, headers, "POST", true);
            if (response != null && response.data != null) {
                setPopularCategories(JSON.parse(response.data.data));

            }



        }

        // call the function
        getPopularCategories().catch(console.error);

    }, [])


    return (
        <>

            <div className="custom-container">
                <Row>

                    <Col md="12">
                        <div className="main-menu-block">

                            <div className="logo-cotnainer">

                                <div className="logo-block">
                                    <a href="/#">
                                        <Media src={adminPanelBaseURL+ LogoImageFromStorage} className="img-fluid  " alt="logo" />
                                    </a>
                                </div>

                            </div>
                            <form className="big-deal-form mb" onSubmit={submitSearchForm}>
                                <InputGroup>

                                    <Input
                                        value={SearchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                    <InputGroupText onClick={(e) => submitSearchForm(e)}>
                                        <span className="search">
                                            <i
                                                className="fa fa-search"></i>
                                        </span>
                                    </InputGroupText>
                                </InputGroup>
                                {ShowSearched ? <>
                                    <div
                                        id="mobile-search-suggestions"
                                        className="bg-white rounded-3 border-top d-flex flex-column gap-2 p-1 position-absolute"
                                    >
                                        {ProductsList.length > 0 ? (ProductsList.map((item, i) => {

                                            return <a href={`/${getLanguageCodeFromSession()}/product-detail/${item.ProductId}/${replaceWhiteSpacesWithDashSymbolInUrl(item.CategoryName) ?? "shop"}/${replaceWhiteSpacesWithDashSymbolInUrl(item.ProductName)}`} key={i}>
                                                <div className="border rounded-3 d-flex rounded-3 gap-1 p-1">
                                                    <img width={50} height={50} src={adminPanelBaseURL + item.ProductImagesJson[0].AttachmentURL} />
                                                    <div className="align-items-center d-flex w-100">
                                                        <span>{item.ProductName}</span>
                                                    </div>
                                                </div>

                                            </a>
                                        })) : (<div className="bg-silver p-2">
                                             {TranslatedText(LocalizationLabelsArray, "No Product Found", "lbl_thead_no_prod_found")}
                                            
                                            
                                            </div>)}

                                    </div>
                                </> : <></>}
                            </form>



                            <div className="mb user" >

                                <MenuUserSection loginText={false} />
                            </div>

                            <div className="input-block">
                                <div className="input-box">
                                    <form className="big-deal-form pc" onSubmit={submitSearchForm}>
                                        <InputGroup>

                                            <Input
                                                value={SearchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                            />
                                            <span className="border-0 h-100" onClick={(e) => submitSearchForm(e)}>
                                                <span className="search">
                                                    <i
                                                        className="fa fa-search"></i>
                                                </span>
                                            </span>

                                        </InputGroup>
                                        {ShowSearched ? <>

                                            <div
                                                id="search-suggestions"
                                                ref={node => {
                                                    nodeElm = node;
                                                }}
                                                className="bg-white rounded-3 border-top d-flex flex-column gap-2 p-1 position-absolute"
                                            >
                                                {ProductsList.length > 0 ? (ProductsList.map((item, i) => {

                                                    return <a href={`/${getLanguageCodeFromSession()}/product-detail/${item.ProductId}/${replaceWhiteSpacesWithDashSymbolInUrl(item.CategoryName) ?? "shop"}/${replaceWhiteSpacesWithDashSymbolInUrl(item.ProductName)}`} key={i}>
                                                        <div className="border rounded-3 d-flex gap-1 p-1">
                                                            <img width={50} height={50} src={adminPanelBaseURL + item.ProductImagesJson[0].AttachmentURL} />
                                                            <div className="align-items-center d-flex w-100">
                                                                <span>{item.ProductName}</span>
                                                            </div>
                                                        </div>

                                                    </a>
                                                })) : (<>  {TranslatedText(LocalizationLabelsArray, "No Product Found", "lbl_thead_no_prod_found")}
                                            </>)}


                                            </div>
                                        </> : <></>}


                                    </form>
                                </div>
                            </div>
                            {
                                <>
                                    <div className={`cart-block ${CartPage ? "cart-page" : ""} cart-hover-div`}>
                                        <div
                                            className="align-items-center d-flex justify-content-center"
                                            style={{ fontSize: "0.9rem" }}
                                        >
                                            <div className="cart ">
                                                <ul>
                                                    <li >
                                                        <a onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCart(e);
                                                        }} href="#">
                                                            <i className="icon-shopping-cart " />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div style={{ color: "white" }}>
                                                <span className="cart-product"> {totalCartItems ?? 0} </span>
                                                <span>  {TranslatedText(LocalizationLabelsArray, "Items", "lbl_thead_items")}
                                            </span>
                                            </div>
                                        </div>
                                        {
                                            showPrice ? <div className="align-items-center d-flex justify-content-center">
                                                <div
                                                    className="cart-price"
                                                    style={{
                                                        color: "green",
                                                        background: "white",
                                                        padding: "0.5rem 1.3rem",
                                                        marginTop: "0.2rem",
                                                        borderRadius: "0.3rem"
                                                    }}
                                                >
                                                    <span>{GetDefaultCurrencySymbol()} {CartSubTotal}</span>
                                                </div>
                                            </div> : <><div
                                                className="cart-price"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleCart(e);
                                                }}
                                                style={{
                                                    color: "green",
                                                    background: "white",
                                                    padding: "0.5rem 1.3rem",
                                                    marginTop: "0.2rem",
                                                    borderRadius: "0.3rem",
                                                    cursor: "pointer"
                                                }}
                                            >
                                                <span>  {TranslatedText(LocalizationLabelsArray, "View Cart", "lbl_thead_view_cart")}
                                            </span>
                                            </div></>
                                        }

                                    </div>
                                </>
                            }

                            <div className="gap-container"></div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );

}


export default SearchHeader;
