import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container, Media } from "reactstrap";
import logoImage from '../../../resources/custom/images/logo_shop.png';
import SubscribeNewsLetter from "../shared/SubscribeNewsLetter";
import { useSelector, useDispatch } from 'react-redux';
import Config from '../../../helpers/Config';
import { MakeApiCallAsync } from '../../../helpers/ApiHelpers';
import { checkIfStringIsEmtpy } from '../../../helpers/ValidationHelper';
import rootAction from '../../../stateManagment/actions/rootAction';
import { LOADER_DURATION } from '../../../helpers/Constants';
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, TranslatedText } from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';

const Footer = ({ layoutLogo }) => {

  const dispatch = useDispatch();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [showFooter, setShowFooter] = useState(true);
  const [adminPanelBaseURL, setadminPanelBaseURL] = useState(Config['ADMIN_BASE_URL']);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const [LogoImageFromStorage, setLogoImageFromStorage] = useState(useSelector(state => state.commonReducer.websiteLogoInLocalStorage));
  let NotShowingFooterRoutes = ['/cart','/checkout'];
  let MobileSize = 991;

  useEffect(() => {
    // declare the data fetching function
    const DataOperationFunc = async () => {


      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',

      }

      const param = {
        requestParameters: {
          recordValueJson: "[]",
        },
      };


      //--Get payment methods
      const responsePaymentMethods = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_PAYMENT_METHODS'], null, param, headers, "POST", true);
      if (responsePaymentMethods != null && responsePaymentMethods.data != null) {
        await setPaymentMethods(JSON.parse(responsePaymentMethods.data.data));

      }

      //--Get Website Logo
      // if (!checkIfStringIsEmtpy(LogoImageFromStorage)) {

        let paramLogo = {
          requestParameters: {
            recordValueJson: "[]",
          },
        };

        let WebsiteLogoInLocalStorage = "";
        let logoResponse = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_WEBSITE_LOGO'], null, paramLogo, headers, "POST", true);
        if (logoResponse != null && logoResponse.data != null) {
          console.log(logoResponse.data)

          if (logoResponse.data.data != "") {
            let logoData = JSON.parse(logoResponse.data.data);
            WebsiteLogoInLocalStorage = logoData[0].AppConfigValue;
            dispatch(rootAction.commonAction.setWebsiteLogo(WebsiteLogoInLocalStorage));
            setLogoImageFromStorage(WebsiteLogoInLocalStorage);
          }


        }
      // }


    }

    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    // call the function
    DataOperationFunc().catch(console.error);

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);


  }, [])

  setInterval(() => {
    if (window.innerWidth < MobileSize) {
      NotShowingFooterRoutes.forEach(route => {
        if (window.location.href.includes(route)){
          setShowFooter(false);
        }
      });
    
  }
}, 500);

  useEffect(() => {
    // declare the data fetching function
    const dataOperationFunc = async () => {

      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["Footer"], null);
      if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    }
    // call the function
    dataOperationFunc().catch(console.error);
  }, [])

  return (
    showFooter == true?
    <footer className="footer-2">
      <Container>
        <Row className="row">
          <Col xs="12">
            <div className="footer-main-contian">
              <Row>
                <Col lg="4" md="12">
                  <div className="footer-left">
                    <div className="footer-logo">
                    <Media src={adminPanelBaseURL +LogoImageFromStorage} className="img-fluid  " alt="logo" />
                    </div>
                    <div className="footer-detail">
                      <p>
                      {/*INTRO HERE*/}
                      </p>
                      
                    </div>
                  </div>
                </Col>
                <Col lg="8" md="12">
                  <div className="footer-right">
                    <Row className="row">
                      <Col md="12">
                        <SubscribeNewsLetter />
                      </Col>
                      <Col md="12">
                        <div className="account-right">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="footer-box">
                                <div className="footer-title">
                                  <h5>my account</h5>
                                </div>
                                <div className="footer-contant">
                                  <ul>
                                    <li>
                                      <Link to={`/${getLanguageCodeFromSession()}/login`} >
                                        Login
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to={`/${getLanguageCodeFromSession()}/about`} id="lbl_footr_about">
                                        {TranslatedText(LocalizationLabelsArray, "About Us", "lbl_footr_about")}
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to={`/${getLanguageCodeFromSession()}/contact-us`} id="lbl_footr_cont">
                                        {TranslatedText(LocalizationLabelsArray, "Contact Us", "lbl_footr_cont")}
                                      </Link>
                                    </li>

                                    <li>
                                      <Link to={`/${getLanguageCodeFromSession()}/signup`} >
                                        Create Account
                                      </Link>
                                    </li>

                                    <li>
                                      <Link to={`/${getLanguageCodeFromSession()}/signup`} >
                                        Become Customer
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="footer-box">
                                <div className="footer-title">
                                  <h5>
                                    {TranslatedText(LocalizationLabelsArray, "Quick Links", "lbl_footr_quicklink")}</h5>
                                </div>
                                <div className="footer-contant">
                                  <ul>
                                    <li>
                                      <Link to={`/${getLanguageCodeFromSession()}/cart`} >
                                        Cart
                                      </Link>
                                    </li>
                                    {/* <li>
                                      <Link to={`/${getLanguageCodeFromSession()}/faq`} id="lbl_footr_faq">
                                        {TranslatedText(LocalizationLabelsArray, " FAQ", "lbl_footr_faq")}
                                      </Link>
                                    </li> */}
                                    <li>
                                      <Link to={`/${getLanguageCodeFromSession()}/`} id="lbl_footr_home">
                                        {TranslatedText(LocalizationLabelsArray, "Home", "lbl_footr_home")}
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to={`/${getLanguageCodeFromSession()}/compare`} >
                                        Compare
                                      </Link>
                                    </li>

                                    <li>
                                      <a href={`/${getLanguageCodeFromSession()}/all-products/0/all-categories`} >
                                        All Products
                                      </a>
                                    </li>

                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div className="footer-box footer-contact-box">
                                <div className="footer-title">
                                  <h5>contact us</h5>
                                </div>
                                <div className="footer-contant">
                                  <ul className="contact-list">
                                    <li>
                                      <i className="fa fa-map-marker"></i>
                                      <span>
                                      Oriental Exotic SPRL <br /> <span>Rue du Compas 44-46</span>
                                      <br /> <span>B-1070 Bruxelles, Belgium</span>
                                      </span>
                                    </li>
                                    <li>
                                      <i className="fa fa-phone"></i>
                                      <span> +32 483-544.195</span>
                                    </li>
                                    <li>
                                      <i className="fa fa-envelope-o"></i>
                                      <span> info@orientalexoticsprl.com</span>
                                    </li>
                                    <li>
                                      <i className="fa fa-fax"></i>
                                      <span>TVA : BE0684.592.544</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="app-link-block  bg-transparent">
        <Container>
          <Row>
            <div className="app-link-bloc-contain app-link-bloc-contain-1">
              {/* <div className="app-item-group">
                <div className="app-item">
                  <Media src="/images/layout-1/app/1.png" className="img-fluid" alt="app-banner" />
                </div>
                <div className="app-item">
                  <Media src="/images/layout-1/app/2.png" className="img-fluid" alt="app-banner" />
                </div>
              </div> */}
              <div className="app-item-group ">
                <div className="social-block">
                  <h6>follow us</h6>
                  <ul className="social">
                    <li>
                      <Link to="https://www.facebook.com/profile.php?id=100090204065904"  target="_blank">
                        <i className="fa fa-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.linkedin.com/" target="_blank">
                        <i className="fa fa-linkedin"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.youtube.com/" target="_blank">
                        <i className="fa fa-youtube-play"></i>
                      </Link>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <div className="sub-footer">
        <Container>
          <Row>
            <Col xs="12">
              <div className="sub-footer-contain">
                <p>
                  <span>Developed by Sysplex Business Solution</span>2022 - 23 copy right by Oriental Exotic SPRL
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>:<></>
  );
};

export default Footer;
