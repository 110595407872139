import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Config from '../../../helpers/Config';
import rootAction from '../../../stateManagment/actions/rootAction';

import { showErrorMsg, showSuccessMsg } from '../../../helpers/ValidationHelper';
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel,TranslatedText } from '../../../helpers/CommonHelper';

import { MakeApiCallAsync } from '../../../helpers/ApiHelpers';


let timerCount = null;
let oldtime = 180;
export default function RegisterOtpModal({ show, onHide, doAfterOTP, email, LocalizationLabelsArray }) {
  const dispatch = useDispatch();
  const [Time, setTime] = useState(180);
  let headersEmailSendOTP = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  function substringEmail() {

    if (email.length <= 4) return;
    let first = email.split("@")[0]
    let second = email.split("@")[1]
    if (first.length > 3) {
      let output = first.slice(0, 3) + "X".repeat(first.length - 3) + "@" + second;
      return output;

    } else {
      let output = first + "@" + second;
      return output;
    }
  }

  async function setTimer() {
    if (timerCount != null) clearInterval(timerCount);
    setTime(180);
    oldtime = 180
    timerCount = setInterval(() => {
      oldtime = oldtime - 1;
      setTime(oldtime)
    }, 1000);
  }

  async function sendOTP() {


    let paramEmailSendOTP = {
      requestParameters: {
        Email: email,
        OtpType: "Register",
        Registered: false
      },
    };

    //--make api call for data operation
    let responseEmailSendOTP = await MakeApiCallAsync(Config.END_POINT_NAMES['ANY_EMAIL_SEND_OTP'], Config["COMMON_CONTROLLER_SUB_URL"], paramEmailSendOTP, headersEmailSendOTP, "POST", true);

    if (responseEmailSendOTP != null && responseEmailSendOTP.data.statusCode != null && responseEmailSendOTP.data.statusCode == 200 && responseEmailSendOTP.data.message == "Sent Successfully") {

      let new_text = TranslatedText(LocalizationLabelsArray, "OTP sent successfully", "lbl_signup_otp_sent");
      showSuccessMsg(new_text);
    } else {
      showErrorMsg(responseEmailSendOTP.data.errorMessage);
      return false;
    }
  }




  async function sendOTPandResetTime() {
    dispatch(rootAction.commonAction.setLoading(true));

    await sendOTP();
    setTimer();
    dispatch(rootAction.commonAction.setLoading(false));

  }


  async function ValidateOTP() {
    let otp_input = document.querySelector('#otp_input');
    if (otp_input.value.length != 6) {
      let error_text = TranslatedText(LocalizationLabelsArray, "OTP must be 6 digits!", "lbl_signup_otp_digits");
      showErrorMsg(error_text);

      return;
    }
    dispatch(rootAction.commonAction.setLoading(true));

    let paramEmailValidateOTP = {
      requestParameters: {
        Email: email,
        Otp: otp_input.value,
        Registered: false
      },
    };

    try {
      //--make api call for data operation
      let responseEmailValidateOTP = await MakeApiCallAsync(Config.END_POINT_NAMES['VALIDATE_ANY_EMAIL_SEND_OTP'], Config["COMMON_CONTROLLER_SUB_URL"], paramEmailValidateOTP, headersEmailSendOTP, "POST", true);

      if (responseEmailValidateOTP != null && responseEmailValidateOTP.data.statusCode != null && responseEmailValidateOTP.data.statusCode == 200 && responseEmailValidateOTP.data.message == "OTP verified successfully") {
        await doAfterOTP();
      } else {
        showErrorMsg(responseEmailValidateOTP.data.errorMessage);
      }
    } catch (error) {
      let error_text = TranslatedText(LocalizationLabelsArray, "An error occurred. Please try again!", "lbl_signup_error_occured");
      showErrorMsg(error_text);

    }

    dispatch(rootAction.commonAction.setLoading(false));
  }
  return (
    <Modal
      show={show}
      onShow={sendOTPandResetTime}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
        <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {TranslatedText(LocalizationLabelsArray, "OTP Verification", "lbl_login_otp_verification")}

        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <p className='text-center'>
          {TranslatedText(LocalizationLabelsArray, "We have sent you a verification code to your registered email address", "lbl_signup_otp_sent_email")}<br></br>
          <b>{substringEmail()}</b>
        </p>
        <br></br>
        <div className='d-flex align-items-center flex-column'>
          <span className='fs-6'>{TranslatedText(LocalizationLabelsArray, "One-Time Password", "lbl_signup_one_time")}</span>
          <input className='form-control' id='otp_input' style={{ width: '7rem' }} type='tel' autoComplete='off' />
        </div>
        <br></br>
        <div className='d-flex align-items-center flex-column'>
          {Time < 1 ? (<>
            <div className='text-red'>{TranslatedText(LocalizationLabelsArray, "Code is expired", "lbl_signup_code_expired")}</div>
          </>) : (<>  <div >{TranslatedText(LocalizationLabelsArray, `This code expires in `, "lbl_signup_code_expire_time1")} {TranslatedText(LocalizationLabelsArray, ` seconds`, "lbl_signup_code_expire_time2")
           }</div></>)}

        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button id='OtpSendBTN' onClick={sendOTPandResetTime}>{TranslatedText(LocalizationLabelsArray, `Resend OTP`, "lbl_login_otp_resend")}</Button>
        <Button onClick={ValidateOTP}>{TranslatedText(LocalizationLabelsArray, `Submit`, "lbl_login_submit")}</Button>
      </Modal.Footer>
    </Modal>
  );
}
