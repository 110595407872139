import React, { Fragment, useContext, useEffect, Component, useState } from "react";
import { Container, Row, Col, Media } from "reactstrap";
import MenuCategory from "./MenuCategory";
import HorizaontalMenu from "./HorizaontalMenu";
import MobileSearch from "./MobileSearch";
import MenuContactUs from "./MenuContactUs";
import MenuGift from "./MenuGift";
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen,replaceLoclizationLabel } from "../../../../helpers/CommonHelper";
import GlobalEnums from "../../../../helpers/GlobalEnums";
import MenuUserSection from "./MenuUserSection";
import { useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import Wishlist from "./Wishlist";



const MegaMenu = () => {

    
    const [MegaMenuLocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    
    useEffect(() => {

        const getDataInUseEffect = async () => {



            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["MegaMenu"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                await setLocalizationLabelsArray(arryRespLocalization);
            }
        }

        // call the function
        getDataInUseEffect().catch(console.error);

    }, [])

    return (
        <>

            <div className="custom-container">
                <Row>
                    <Col>
                        <div className="navbar-menu">
                            <div className="category-left col-9">
                                <MenuCategory MegaMenuLocalizationLabelsArray={MegaMenuLocalizationLabelsArray} />
                                <HorizaontalMenu MegaMenuLocalizationLabelsArray={MegaMenuLocalizationLabelsArray} />
                               
                            </div>
                            <div className="category-right">
                                <MenuContactUs spanClass="" MegaMenuLocalizationLabelsArray={MegaMenuLocalizationLabelsArray} />
                                <MenuGift />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );

}


export default MegaMenu;
