import React, { useEffect, useState } from 'react';
import { Link, useFetcher } from 'react-router-dom';
import { GetDefaultCurrencySymbol, GetUserInformation } from '../../../helpers/CommonHelper';
import { Modal, ModalBody, Button } from "reactstrap";
import Config from '../../../helpers/Config';


const SelectedProductVariants = (props) => {
    const [adminPanelBaseURL, setadminPanelBaseURL] = useState(Config['ADMIN_BASE_URL']);

    const [showPrice, setShowPrice] = useState(false);
    useEffect(() => {
        (async function () {
            if (props.showProductVariantsPopup == false) return;
            const userInfo = await GetUserInformation();
            setShowPrice(userInfo.ShowPrice);
        })();
    }, [props.showProductVariantsPopup])

    return (
        <>
            <Modal isOpen={props.showProductVariantsPopup} toggle={props.closeProductVariantPopup} centered={true} className="theme-modal" id="exampleModal" role="dialog" aria-hidden="true">
                <ModalBody className="modal-content">
                    <Button className="close" data-dismiss="modal" aria-label="Close"
                        onClick={(e) => {
                            e.preventDefault();

                            props.closeProductVariantPopup();
                        }}
                    >
                        <span aria-hidden="true">×</span>
                    </Button>
                    <div className="news-latter">
                        <div >
                            <div className="offer-content" >
                                <div>
                                    <img src={adminPanelBaseURL + props.ProductImage} height={150} className='mb-lg-4' ></img>

                                    <ul className="list-group">

                                        {(() => {
                                            return (
                                                props.productSelectedAttributes?.map((atrItem, atrIdx) =>

                                                    <li className="list-group-item" key={atrIdx}>

                                                        <h3 className="product-variant-title">{atrItem.AttributeDisplayName}</h3>
                                                        <div className="form-check form-check-inline">
                                                            <input type="radio"
                                                                className="form-check-input"
                                                                checked={true}
                                                                readOnly={true}
                                                            />
                                                            <label className="form-check-label" for={atrItem.PrimaryKeyDisplayValue}>
                                                                {atrItem.PrimaryKeyDisplayValue}

                                                            </label>
                                                        </div>
                                                    </li>
                                                )

                                            );


                                        })()}





                                    </ul>

                                    <div className='mt-4'>
                                        {showPrice ? <label>Price: {GetDefaultCurrencySymbol() + " " + props.productAttributePrice}</label> : ""}
                                        <br></br>
                                        <button className='btn btn-primary px-4' onClick={(e) => {
                                            e.preventDefault();
                                            props.closeProductVariantPopup();
                                        }}>Close</button>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </ModalBody>
            </Modal>




        </>
    );
}

export default SelectedProductVariants;