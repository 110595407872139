import React, { useContext, useEffect, useState, Component } from "react";
import { Row, Col, Dropdown, DropdownToggle } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';

import GlobalEnums from '../../../../helpers/GlobalEnums';
import Config from '../../../../helpers/Config';
import { MakeApiCallAsync } from "../../../../helpers/ApiHelpers";
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel, setLanguageCodeInSession,TranslatedText } from '../../../../helpers/CommonHelper';
import { Link } from 'react-router-dom';
import CountDownAnnouncement from "../../shared/CountDownAnnouncement";

const langCodeArray = [
  {
    langCode: "en",
    name: "English"
  },
  {
    langCode: "ar",
    name: "Arabic"
  }
]


const Announcement = ({setHasClass}) => {

  const { i18n, t } = useTranslation();
  const [openLang, setOpenLang] = useState(false);
  const [AnnouncementDetails, setAnnouncementDetails] = useState({
    IsActive: false,
    NewsIsActive: false,
    AnnounceText: "",
    EndDate: "",
    NewsAlert: "",
    Ended: true
  });
  const [url, setUrl] = useState("");
  const toggleLang = () => {
    setOpenLang(!openLang);
  };

  const loginUserDataJson = useSelector(state => state.userReducer.user);
  const loginUser = JSON.parse(loginUserDataJson ?? "{}");

  useEffect(() => {
    const path = window.location.pathname.split("/");
    const urlTemp = path[path.length - 1];
    setUrl(urlTemp);
  }, []);


  const [langCode, setLangCode] = useState('');
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const handleLangCodeInSession = async (value) => {

    await setLanguageCodeInSession(value);
    await setLangCode(value);
    let homeUrl = '/' + value + '/';
    window.location.href = homeUrl;
    // navigate(homeUrl, { replace: true });
  }

  useEffect(() => {
    // declare the data fetching function
    const dataOperationFunc = async () => {
      let lnCode = getLanguageCodeFromSession();
      setLangCode(lnCode);

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }




      const AnnounceResponse = await MakeApiCallAsync(Config.END_POINT_NAMES["GET_ANNOUNCEMENT"], Config['COMMON_CONTROLLER_SUB_URL'], null, headers, "GET", true);
      if (AnnounceResponse != null && AnnounceResponse.data != null) {

        let finalData = JSON.parse(AnnounceResponse.data.data);
        console.log("announcement=>", finalData);
        setAnnouncementDetails(finalData);

      }

      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["TopHeader"], null);
      if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    }
    // call the function
    dataOperationFunc().catch(console.error);
  }, [])

  useEffect(()=>{
    let has = "";

    if (AnnouncementDetails.IsActive == true && AnnouncementDetails?.Ended == false){
      has +="-ann";
    }
    if (AnnouncementDetails.NewsIsActive == true){
      has +="-news";
    }
    setHasClass("has"+has);
  },[AnnouncementDetails])



  return (
    <>
      {AnnouncementDetails.IsActive == true ? <>
        <div className={`announce-header ${AnnouncementDetails?.Ended == true ? "d-none" : ""}`}>
          <div className="custom-container w-100">
          <div className="mobile-announceText">{TranslatedText(LocalizationLabelsArray, AnnouncementDetails.AnnounceText, "lbl_announce_text")}
          
          </div>
            <div className="align-items-center d-flex font-semibold gap-2 justify-content-center">
              <div className="announce-text">
                <div className="desktop-announceText">{TranslatedText(LocalizationLabelsArray, AnnouncementDetails.AnnounceText, "lbl_announce_text")}</div>
                <CountDownAnnouncement endDate={AnnouncementDetails?.EndDate} AnnouncementDetails={AnnouncementDetails} setAnnouncementDetails={setAnnouncementDetails} />

              </div>  
              <div>
                <a href={`/${getLanguageCodeFromSession()}/offer-products/0/all-categories`} className="btn btn-primary" style={{ background: '#E91E63' }}>
                {TranslatedText(LocalizationLabelsArray, "Shop Now!", "lbl_announce_button_text")}
                  
                  </a>
              </div>
            </div>
          </div>
        </div>
      </> : <></>}
      {AnnouncementDetails.NewsIsActive == true ? <>
        <div className="news-header ">
          <div className="custom-container w-100">
            <div className="d-flex">
              <marquee className="">
                <div className="d-flex" style={{ gap: "5rem" }}>
                  <div> {TranslatedText(LocalizationLabelsArray, AnnouncementDetails.NewsAlert, "lbl_announce_news_alert")}</div>
                </div>
              </marquee>
            </div>
          </div>
        </div>
      </> : <></>}


    </>
  );

}


export default Announcement;
