import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import Img from "../../../helpers/utils/BgImgRatio";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import Config from '../../../helpers/Config';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import { getLanguageCodeFromSession, ScrollIntoSpecificDiv } from "../../../helpers/CommonHelper";
import rootAction from "../../../stateManagment/actions/rootAction";
import { Media, Row, Col, Container } from "reactstrap";
import {  replaceWhiteSpacesWithDashSymbolInUrl } from "../../../helpers/ConversionHelper";

import { LOADER_DURATION } from "../../../helpers/Constants";
let errorImageUrl = Config['ADMIN_BASE_URL'] + "/content/themeContent/global_assets/images/placeholders/placeholder.jpg";
const Category = () => {
  const dispatch = useDispatch();
  const search = useLocation().search;
  const [adminPanelBaseURL, setBaseUrl] = useState(Config['ADMIN_BASE_URL']);
  const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);
  const [cols, setCols] = useState("col-xl-3 col-md-4 col-6 col-grid-box");
  const [CategoriesList, setCategoriesList] = useState([]);
  const [MainCategoriesList, setMainCategoriesList] = useState([]);
  const [SubCategoriesList, setSubCategoriesList] = useState([]);

  const getSubCategory = (CategoryID) => {
    return SubCategoriesList.filter((el) => { return el.ParentCategoryID == CategoryID });
  }
  const getAllCategoriesAfterAnyFilterChange = async () => {


    try {


      //--start loader
      dispatch(rootAction.commonAction.setLoading(true));

      let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }


      const param = {
        requestParameters: {
          PageNo: 1,
          PageSize: 100,
          recordValueJson: "[]",
        },
      };

      //--Get categories list
      const categoriesResponse = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_CATEGORIES_LIST'], null, param, headers, "POST", true);
      if (categoriesResponse != null && categoriesResponse.data != null) {
        await setCategoriesList(JSON.parse(categoriesResponse.data.data));
        let data = JSON.parse(categoriesResponse.data.data);
        setMainCategoriesList(data.filter((el) => { return el.ParentCategoryID == null }));
        setSubCategoriesList(data.filter((el) => { return el.ParentCategoryID !== null }));

        console.log(JSON.parse(categoriesResponse.data.data))

      }

      //--stop loader
      setTimeout(() => {
        dispatch(rootAction.commonAction.setLoading(false));
      }, LOADER_DURATION);

    }
    catch (error) {

      //--stop loader
      setTimeout(() => {
        dispatch(rootAction.commonAction.setLoading(false));
      }, LOADER_DURATION);

    }
  }
  function getSubCategoriesIds(CategoryID) {
    let subcategories = getSubCategory(CategoryID);

    return subcategories.map((item,i)=>{return item.CategoryID}).join(',');
  }

  useEffect(() => {
    getAllCategoriesAfterAnyFilterChange()
  }, []);
  return (
    <>


      <Helmet>
        <title>{siteTitle} - Category</title>
        <meta name="description" content={siteTitle + " - Categories page"} />
        <meta name="keywords" content="Categories"></meta>
      </Helmet>

      <SiteBreadcrumb title="Categories" parent="Home" title_link='' />
      <section className="all-categories-wrap">
        <div className="px-lg-5">

          <div className="all-categories">
            {MainCategoriesList &&
              MainCategoriesList?.map((item, i) => (
                <div className="single-category-wrap" key={i}>
                  <div className="single-category">
                    <div className="img" style={{backgroundColor:item.HexCode}}>
                      <img
                        src={adminPanelBaseURL + item.AttachmentURL}
                        className="img-fluid border"
                        onError={(e) => {
                          e.target.src = errorImageUrl // some replacement image
                        }}
                      />
                    </div>
                    <h4 className="section-title">
                      <a
                        href={`/${getLanguageCodeFromSession()}/all-products/${getSubCategoriesIds(item.CategoryID)}`}
                        title={item.Name}
                      >
                        <span style={{color:item.HexCode}}>{item.Name}</span>
                        <i className="las la-angle-double-right" />
                      </a>
                    </h4>
                    <div className="sub-categories">

                      <ul className="list-inline single-category-list">
                        {getSubCategory(item.CategoryID)?.map((item2,i)=>( <li key={i}>
                          <a
                            href={`/${getLanguageCodeFromSession()}/all-products/${item2.CategoryID}/${replaceWhiteSpacesWithDashSymbolInUrl(item2.Name)}`}
                            title={item2.Name}
                          >
                            {item2.Name}
                          </a>
                        </li>))}
                       

                      </ul>
                    </div>
                    <div className="shop-now">
                      <a
                        className="btn btn-normal p-3 w-100"
                        id="lbl_prd_det_addcart"
                        href={`/${getLanguageCodeFromSession()}/all-products/${getSubCategoriesIds(item.CategoryID)}`}
                      >
                        View All
                      </a>
                    </div>
                  </div>
                </div>
                ))
            }

          </div>
        </div>
      </section>
    </>
  );

}

export default Category;
