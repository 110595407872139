import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MakeApiCallSynchronous, MakeApiCallAsync } from '../../../helpers/ApiHelpers';
import { GetDefaultCurrencySymbol,GetUserInformation } from '../../../helpers/CommonHelper';
import { Modal, ModalBody, Button } from "reactstrap";
import Config from '../../../helpers/Config';


const ProductVariants = (props) => {

   
    const [showPrice, setShowPrice] = useState(false);
    const [adminPanelBaseURL, setadminPanelBaseURL] = useState(Config['ADMIN_BASE_URL']);
    const [productAllAttributes, setProductAllAttributes] = useState([]);



    useEffect(() => {
        if (props.showProductVariantsPopup == false) return;
        // declare the data fetching function
        const getProductAllAttributesById = async () => {
       
            if (props.ProductId == 0){
                return;
            }
            const userInfo = await GetUserInformation();
            console.log(userInfo);
            setShowPrice(userInfo.ShowPrice);
          
            const headersProdAttribte = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }


            const paramProdAttribute = {
                requestParameters: {
                    ProductId: props.ProductId,
                    recordValueJson: "[]",
                },
            };


            //--Get product all attributes by product id
            const responseProdAttributes = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_PRODUCT_ALL_ATTRIBUTES_BY_ID'], null, paramProdAttribute, headersProdAttribte, "POST", true);
            if (responseProdAttributes != null && responseProdAttributes.data != null) {

                //await setProductAllAttributes(JSON.parse(responseProdAttributes.data.data).filter(x=>x.AttributeDisplayName!="Colors" && x.AttributeDisplayName!="Size"));
               await setProductAllAttributes(JSON.parse(responseProdAttributes.data.data));
                await props.setProductAllAttributes(JSON.parse(responseProdAttributes.data.data));
            
            }

        }


        // call the function
        getProductAllAttributesById().catch(console.error);


    }, [props.showProductVariantsPopup])


    return (
        <>



            <Modal isOpen={props.showProductVariantsPopup} toggle={props.closeProductVariantPopup} centered={true} className="theme-modal" id="exampleModal" role="dialog" aria-hidden="true">
                <ModalBody className="modal-content">
                    <Button className="close" data-dismiss="modal" aria-label="Close"
                        onClick={(e) => {
                            e.preventDefault();
                            props.closeProductVariantPopup();
                        }}
                    >
                        <span aria-hidden="true">×</span>
                    </Button>
                    <div className="news-latter">
                        <div >
                            <div className="offer-content" >
                                <div>
                                    <img src={adminPanelBaseURL+props.ProductImage?.AttachmentURL} height={150} className='mb-lg-4' ></img>

                                    <ul className="list-group">

                                        {(() => {
                                            let attributeNames =productAllAttributes.map(({ ProductAttributeID, AttributeDisplayName }) => ({ ProductAttributeID, AttributeDisplayName }));
                                            let uniqueAttributeNames = [...new Map(attributeNames.map((item) => [item["ProductAttributeID"], item])).values(),];
                                            return (
                                                uniqueAttributeNames?.map((atrItem, atrIdx) =>

                                                    <li className="list-group-item" key={atrIdx}>
                                                            
                                                        <h3 className="product-variant-title">{atrItem.AttributeDisplayName}</h3>

                                                        {(() => {
                                                            let RowData =productAllAttributes?.filter(x => x.ProductAttributeID == atrItem.ProductAttributeID)
                                                            return (
                                                                RowData?.map((rowItem, rowIdx) =>
                                                                    <div className="form-check form-check-inline">
                                                                        <input type="radio"
                                                                            className="form-check-input"
                                                                            name={rowItem.ProductAttributeID + rowItem.AttributeDisplayName}
                                                                            id={rowItem.ProductAttributeID + rowItem.AttributeDisplayName + rowItem.PrimaryKeyValue}
                                                                            checked={props.getSelectedVariant(rowItem.ProductAttributeID).PrimaryKeyValue === rowItem.PrimaryKeyValue?true:false }
                                                                           
                                                                            onChange={(e) =>{ props.setProductVariantsFromPopup(rowItem.PrimaryKeyValue, rowItem.ProductAttributeID,rowItem.AdditionalPrice,rowItem.PrimaryKeyDisplayValue); }}
                                                                        />
                                                                       
                                                                        <label className="form-check-label" for={rowItem.PrimaryKeyValue + rowItem.AttributeDisplayName + rowItem.PrimaryKeyValue}>
                                                                            {rowItem.PrimaryKeyDisplayValue}

                                                                        </label>


                                                                    </div>
                                                                )
                                                            );
                                                        })()}


                                                    </li>
                                                )

                                            );


                                        })()}





                                    </ul>

                                    <div className='mt-4'>
                                       {showPrice?<label>Price: { GetDefaultCurrencySymbol() +" "+ props.getSelectedVariant(-1)?.AdditionalPrice}</label>:<></>} 
                                        <br></br>
                                            <button className='btn btn-primary px-4' onClick={(e) => {
                                                e.preventDefault();
                                                props.confirmProductVariantPopup();
                                            }}>Confirm</button>
                                        </div>

                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                </ModalBody>
            </Modal>




        </>
    );
}

export default ProductVariants;