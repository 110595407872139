import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import Img from "../../../helpers/utils/BgImgRatio";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import Config from '../../../helpers/Config';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import { getLanguageCodeFromSession, ScrollIntoSpecificDiv } from "../../../helpers/CommonHelper";
import rootAction from "../../../stateManagment/actions/rootAction";
import { Media, Row, Col, Container } from "reactstrap";

import { LOADER_DURATION } from "../../../helpers/Constants";
let errorImageUrl = Config['ADMIN_BASE_URL'] + "/content/themeContent/global_assets/images/placeholders/placeholder.jpg"; 
const Brand = () => {
  const dispatch = useDispatch();
  const search = useLocation().search;
  const [adminPanelBaseURL, setBaseUrl] = useState(Config['ADMIN_BASE_URL']);
  const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);
  const [cols, setCols] = useState("col-xl-3 col-md-4 col-6 col-grid-box");
  const [grid, setGrid] = useState(cols);
  const [SearchTerm, setSearchTerm] = useState(new URLSearchParams(search).get('SearchTerm'));
  const [BrandsList, setBrandsList] = useState([]);
  const [TotalRecords, setTotalRecords] = useState(0);

  const getAllBrandsAfterAnyFilterChange = async () => {


    try {


      //--start loader
      dispatch(rootAction.commonAction.setLoading(true));

      let headersFromPage = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }


      let paramFromPage = {
        requestParameters: {
          SearchTerm: SearchTerm ?? "",
          recordValueJson: "[]",
        },
      };


      let responseAllBrands = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_All_BRANDS'], null, paramFromPage, headersFromPage, "POST", true);
      if (responseAllBrands != null && responseAllBrands.data != null) {

        await setBrandsList(JSON.parse(responseAllBrands.data.data));
        let AllBrands = JSON.parse(responseAllBrands.data.data);
        await setTotalRecords(parseInt(AllBrands[0]?.TotalRecords ?? 0))
     

      }


      //--stop loader
      setTimeout(() => {
        dispatch(rootAction.commonAction.setLoading(false));
      }, LOADER_DURATION);

    }
    catch (error) {

      //--stop loader
      setTimeout(() => {
        dispatch(rootAction.commonAction.setLoading(false));
      }, LOADER_DURATION);

    }






  }

  useEffect(() => {
    getAllBrandsAfterAnyFilterChange()
  }, []);
  return (
    <>


      <Helmet>
        <title>{siteTitle} - Brand</title>
        <meta name="description" content={siteTitle + " - Brands page"} />
        <meta name="keywords" content="Brands"></meta>
      </Helmet>

      <SiteBreadcrumb title="Brands" parent="Home" title_link='' />

      <section>
        <div className="custom-container brand-listing mt-2 d-flex flex-wrap justify-content-center">


          {!BrandsList || BrandsList.length === 0 ? (
            <Col xs="12">
              <div>
                <div className="col-sm-12 empty-cart-cls text-center">
                      <h3>
                          <strong>No Brand found</strong>
                      </h3>
                  </div>
              </div>
            </Col>
          ) : (
            BrandsList &&
            BrandsList?.map((item, i) => (
              <div key={i}>
                {(() => {

                  let urlViewDetailImage = `/${getLanguageCodeFromSession()}/brand-detail/${item.ManufacturerID}`;
                  return (
                    <>
                      <a href={urlViewDetailImage}>
                        <img
                          src={adminPanelBaseURL + item.AttachmentURL}
                          className="img-fluid border"
                          onError={(e) => {
                            e.target.src = errorImageUrl // some replacement image
                         }}
                        />
                        <div className="brand-name">{item.Name}</div>
                      </a>
                    </>
                  );
                })()}
              </div>
            ))
          )}



        </div>
      </section>

    </>
  );

}

export default Brand;
