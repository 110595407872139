import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import { useSelector, useDispatch } from 'react-redux';
import { LOADER_DURATION } from '../../../helpers/Constants';
import SelectedProductVariants from '../../components/modal/SelectedProductVariants';

import { MakeApiCallSynchronous, MakeApiCallAsync } from '../../../helpers/ApiHelpers';
import Config from '../../../helpers/Config';
import rootAction from '../../../stateManagment/actions/rootAction';
import { convertDateToDifferentFormats, getFileExtensionFromContentType, makeProductShortDescription, replaceWhiteSpacesWithDashSymbolInUrl } from '../../../helpers/ConversionHelper';
import { Helmet } from 'react-helmet';
import { GetDefaultCurrencySymbol, getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, GetUserIdForHeader, TranslatedText, ScrollIntoSpecificDiv, GetUserInformation } from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';
import axios from 'axios';
import BestFacilities from '../../components/shared/BestFacilities';
import { Media, Row, Col } from "reactstrap";

const OrdersHistory = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPrice, setShowPrice] = useState(false);
    const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);
    const [adminPanelBaseURL, setadminPanelBaseURL] = useState(Config['ADMIN_BASE_URL']);
    const [OrderMasterList, setOrderMasterList] = useState([]);
    const [OrderItemsDetailList, setOrderItemsDetailList] = useState([]);

    const [productSelectedAttributes, setProductSelectedAttributes] = useState([]);
    const [productAttributePrice, setproductAttributePrice] = useState(0);
    const [ProductInfo, setProductInfo] = useState([]);
    const [showProductVariantsPopup, setShowProductVariantsPopup] = useState(false);

    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

    //-get login user from session
    const loginUserDataJson = useSelector(state => state.userReducer.user);
    const loginUser = JSON.parse(loginUserDataJson ?? "{}");

    const viewSelectedAttributesOfCartItem = async (ProductId, ProductInfo) => {


        //-- first empty existing data
        setProductSelectedAttributes([]);

        const headersProdAttribte = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }


        const paramProdAttribute = {
            requestParameters: {
                ProductId: ProductId,
                recordValueJson: "[]",
            },
        };


        //--Get product all attributes by product id
        const responseProdAttributes = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_PRODUCT_ALL_ATTRIBUTES_BY_ID'], null, paramProdAttribute, headersProdAttribte, "POST", true);
        if (responseProdAttributes != null && responseProdAttributes.data != null && responseProdAttributes.data.data != null) {

            let ProductAllAttributes = JSON.parse(responseProdAttributes.data.data);
            let productAttr = ProductInfo.ProductAttributes;

            let extractedAttributes = [];

            if (productAttr != undefined && productAttr != null) {
                for (let index = 0; index < productAttr.length; index++) {
                    let localRowAttr = productAttr[index];
                    let elementGet = ProductAllAttributes.find(x => x.ProductAttributeID == localRowAttr.ProductAttributeID && x.PrimaryKeyValue == Number(localRowAttr.AttributeValue));
                    extractedAttributes.push(elementGet);
                }
            }
            setProductSelectedAttributes(extractedAttributes);
            setproductAttributePrice(extractedAttributes[0].AdditionalPrice)
            setShowProductVariantsPopup(true);
            setProductInfo(ProductInfo)
            console.log(extractedAttributes);
        }



        //--show the popup
    }

    const viewOrderItemsDetails = async (OrderId) => {
        //--start loader
        dispatch(rootAction.commonAction.setLoading(true));


        const headersDetail = {
            // customerid: userData?.UserID,
            // customeremail: userData.EmailAddress,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }


        const paramDetail = {
            requestParameters: {
                OrderId: OrderId,
                recordValueJson: "[]",
            },
        };


        let responseDetailOrderDetail = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_CUSTOME_ORDER_HISTORY_DETAIL'], null, paramDetail, headersDetail, "POST", true);
        if (responseDetailOrderDetail != null && responseDetailOrderDetail.data != null) {
            await setOrderItemsDetailList(JSON.parse(responseDetailOrderDetail.data.data));
            console.log(JSON.parse(responseDetailOrderDetail.data.data));
        }


        //--stop loader
        setTimeout(() => {
            dispatch(rootAction.commonAction.setLoading(false));
        }, LOADER_DURATION);

        try {
            ScrollIntoSpecificDiv("order_item_detail", "smooth");
        }
        catch (err) {

            console.log(err.message);
        }


    }
   

    useEffect(() => {
        // declare the data fetching function
        const getOrderHistoryMaster = async () => {
            const userInfo = await GetUserInformation();
            setShowPrice(userInfo.ShowPrice);

            const headers = {
                // customerid: userData?.UserID,
                // customeremail: userData.EmailAddress,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }


            const param = {
                requestParameters: {
                    UserId: loginUser.UserID,
                    recordValueJson: "[]",
                },
            };


            const response = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_CUSTOMER_ORDER_HISTORY_MASTER'], null, param, headers, "POST", true);
            if (response != null && response.data != null) {
                await setOrderMasterList(JSON.parse(response.data.data));
                console.log(JSON.parse(response.data.data));
            }

            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["OrdersHistory"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                await setLocalizationLabelsArray(arryRespLocalization);
            }


        }

        //--start loader
        dispatch(rootAction.commonAction.setLoading(true));

        // call the function
        getOrderHistoryMaster().catch(console.error);

        //--stop loader
        setTimeout(() => {
            dispatch(rootAction.commonAction.setLoading(false));
        }, LOADER_DURATION);

    }, [])



    return (
        <>
            <Helmet>
                <title>{siteTitle} - Orders History</title>
                <meta name="description" content={siteTitle + " Orders History"} />
                <meta name="keywords" content="Orders History"></meta>
            </Helmet>

            <SiteBreadcrumb title="Order History" parent="Home" />
            <div>

                <section className="cart-section order-history section-big-py-space">
                    <div className="custom-container">
                        <Row>
                            <Col sm="12">
                                <table className="table cart-table table-responsive-xs">
                                    <thead>
                                        <tr className="table-head">
                                            <th scope="col">
                                                {TranslatedText(LocalizationLabelsArray, "Order #", "lbl_ordrhis_orderno")}
                                            </th>
                                            <th scope="col">
                                                {TranslatedText(LocalizationLabelsArray, "Order Date", "lbl_ordrhis_orderdte")}
                                            </th>
                                            <th scope="col">
                                                {TranslatedText(LocalizationLabelsArray, "Status", "lbl_ordrhis_orderstatus")}
                                            </th>
                                            <th scope="col">
                                                {TranslatedText(LocalizationLabelsArray, "QTY", "lbl_ordrhis_totalitem")}
                                            </th>
                                            {/* {showPrice ?
                                                <th scope="col">
                                                    {TranslatedText(LocalizationLabelsArray, "Total", "lbl_ordrhis_total")}
                                                </th> : <></>
                                            } */}



                                            <th scope="col">
                                                {TranslatedText(LocalizationLabelsArray, "Detail", "lbl_ordrhis_detail")}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {OrderMasterList?.map((item, idx) => (
                                            <tr key={idx}>

                                                <td>
                                                    <a href="#">
                                                        <span className="dark-data">{item.OrderNumber}</span>
                                                    </a>

                                                </td>
                                                <td>
                                                    <a href="#">
                                                        {convertDateToDifferentFormats(item.OrderDateUTC, 'dd-mm-yyyy')}
                                                    </a>
                                                </td>
                                                <td>

                                                    <span className="dark-data">  {item.LatestStatusName}</span>
                                                </td>
                                                <td>
                                                    <span>{item.TotalItems}</span>

                                                </td>

                                                {/* {showPrice ? <td>

                                                    <span className="dark-data">  {GetDefaultCurrencySymbol()}{item.OrderTotal}</span>
                                                </td> :<></>} */}
                                                <td>
                                                    <Link to="#"
                                                        className="order-history-view"
                                                        onClick={() => { viewOrderItemsDetails(item.OrderId) }}
                                                    >
                                                        <i className="fa fa-eye"></i>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </Col>
                        </Row>
                        <Row className="cart-buttons">
                            <Col xs="12" className="pull-right">

                                <Link to={`/${getLanguageCodeFromSession()}/`} className="btn btn-normal btn-sm" id="lbl_ordrhis_continue_ship">
                                    {TranslatedText(LocalizationLabelsArray, "Continue Shopping", "lbl_ordrhis_continue_ship")}
                                </Link>
                            </Col>
                        </Row>



                    </div>
                </section>

            </div>


            {
                OrderItemsDetailList != undefined && OrderItemsDetailList != null && OrderItemsDetailList.length > 0
                    ?

                    <section id="order_item_detail" className="cart-section order-detail section-big-py-space">
                        <div className="custom-container">
                            <Row className="cart-buttons" >
                                <Col sm="12">
                                    <div className="title6">
                                        <h4>
                                            {TranslatedText(LocalizationLabelsArray, "Cart Items", "lbl_ordrhis_cartitm")}
                                        </h4>
                                    </div>
                                    <table className="table cart-table table-responsive-xs mt-4">
                                        <thead>
                                            <tr className="table-head">
                                                <th scope="col" colSpan={2} id="lbl_ordrhis_prd">
                                                    {TranslatedText(LocalizationLabelsArray, "Product", "lbl_ordrhis_prd")}
                                                </th>
                                              
                                                <th scope="col">
                                                    {TranslatedText(LocalizationLabelsArray, "Category Name", "lbl_hdr_cart_cat")}
                                                </th>

                                             
                                                {showPrice ? <th scope="col" id="lbl_ordrhis_price">
                                                    {TranslatedText(LocalizationLabelsArray, "Unit Price", "lbl_ordrhis_price")}
                                                </th>  : <></>}
                                                <th scope="col" id="lbl_ordrhis_qty">
                                                    {TranslatedText(LocalizationLabelsArray, "QTY", "lbl_ordrhis_qty")}
                                                </th>
                                                {showPrice ?  <th scope="col" id="lbl_ordrhis_itmtotal">
                                                    {TranslatedText(LocalizationLabelsArray, "Value", "lbl_ordrhis_itmtotal")}
                                                </th>  : <></>}
                                                {/* <th scope="col" id="lbl_ordrhis_isdigital">
                                                    {TranslatedText(LocalizationLabelsArray, "Is Digital Product", "lbl_ordrhis_isdigital")}
                                                </th> */}
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {OrderItemsDetailList?.map((itemDetail, idx) => (
                                                <tr key={idx} className="item">
                                                    <td>
                                                        <a href="#">
                                                            <Media src={adminPanelBaseURL + itemDetail.DefaultImageUrl} alt="product" className="img-fluid" />
                                                        </a>
                                                    </td>
                                                    <td style={{textAlign: 'left'}}>
                                                        <a href="#">
                                                            <h5>  {itemDetail.ProductName}</h5>
                                                          
                                                        </a>
                                                        <span style={{ fontSize: '13px' }}>{itemDetail.ProductAttributes !== undefined && itemDetail.ProductAttributes.length > 0 ? itemDetail.ProductAttributes[0].AttributeDisplayName : ""}</span>

                                                    </td>
                                                    <td>
                                                        <div>{itemDetail.CategoryName}</div>
                                                    </td>
                                                  

                                                    {showPrice ? <td>
                                                        <h4>{GetDefaultCurrencySymbol()}{itemDetail.Price}</h4>
                                                    </td> : <></>}
                                                    <td>

                                                        <span>{itemDetail.Quantity}</span>
                                                    </td>
                                                    {showPrice ?<td>
                                                        <h4>{GetDefaultCurrencySymbol()}{(itemDetail.OrderItemTotal)}</h4>
                                                    </td> : <></>}
                                                    {/* <td>

                                                        <span>
                                                            {
                                                                itemDetail?.IsDigitalProduct != undefined && itemDetail.IsDigitalProduct == true &&
                                                                    (itemDetail.LatestStatusID == Config.ORDER_STATUS["Completed"] || itemDetail.ShippingStatusID == Config.ORDER_STATUS["Completed"]) ?
                                                                    (
                                                                        <Link to="#" className="digital-download-btn"
                                                                            onClick={() => { downloadDigitalProduct(itemDetail.OrderItemID, itemDetail.ProductName) }}
                                                                        >
                                                                            Download
                                                                        </Link>
                                                                    )
                                                                    : itemDetail?.IsDigitalProduct == true ? (
                                                                        "Yes (In Progress)"
                                                                    ) : (
                                                                        "No"
                                                                    )
                                                            }
                                                        </span>
                                                    </td> */}

                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                </Col>
                            </Row>

                        </div>
                    </section>
                    :
                    <>
                    </>
            }
            <BestFacilities />


            <SelectedProductVariants
                showProductVariantsPopup={showProductVariantsPopup}
                closeProductVariantPopup={() => { setShowProductVariantsPopup(!showProductVariantsPopup); }}
                productSelectedAttributes={productSelectedAttributes}
                productAttributePrice={productAttributePrice}
                ProductImage={ProductInfo.DefaultImageUrl}
            />
        </>
    );
}

export default OrdersHistory;
