import React, { useEffect, useState } from 'react';
import { MakeApiCallAsync } from '../../../helpers/ApiHelpers';
import { useSelector, useDispatch } from 'react-redux';
import Config from '../../../helpers/Config';
import { showErrorMsg, showSuccessMsg, validateAnyFormField } from '../../../helpers/ValidationHelper';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import BestFacilities from '../../components/shared/BestFacilities';
import rootAction from '../../../stateManagment/actions/rootAction';
import { LOADER_DURATION } from '../../../helpers/Constants';
import { Helmet } from 'react-helmet';
import { GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel ,TranslatedText} from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';
import { Input, Row, Col, Form, FormGroup, Label, } from "reactstrap";
import ContactOtpModal from '../../components/modal/ContactOtpModal';



const ContactUs = () => {
  const dispatch = useDispatch();
  const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const [FullName, setFullName] = useState('');
  const [Email, setEmail] = useState('');
  const [PhoneNumber, setPhoneNumber] = useState('');
  const [Subject, setSubject] = useState('Account');
  const [Message, setMessage] = useState('');
  const [NRemaining, setNRemaining] = useState(500);
  const [OtpModal, setOtpModal] = useState(false);

  const doAfterOTP = async () => {

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }


    const param = {
      requestParameters: {
        FullName: FullName,
        Email: Email,
        PhoneNumber: PhoneNumber,
        Subject: Subject,
        Message: Message

      },
    };


    //--make api call for data operation
    const response = await MakeApiCallAsync(Config.END_POINT_NAMES['CONTACT_US'], null, param, headers, "POST", true);
    if (response != null && response.data != null) {
      let userData = JSON.parse(response.data.data);
      if (userData.length > 0 && userData[0].ResponseMsg != undefined && userData[0].ResponseMsg == "Saved Successfully") {
        const param2 = {
          requestParameters: {
            Email: Email,
            Name: FullName,
            PhoneNumber: PhoneNumber,
            Subject: Subject,
            Message: Message
          },
        };

        const response2 = await MakeApiCallAsync(Config.END_POINT_NAMES['SEND_CONTACTUS_EMAIL'], Config['COMMON_CONTROLLER_SUB_URL'], param2, headers, "POST", true);
        if (response2 != null && response2.data != null) {


          showSuccessMsg("Message sent successfully!");

          //--Empty form fields
          setFullName('');
          setEmail('');
          setPhoneNumber('');
          setSubject('');
          setMessage('');
          setOtpModal(false);
          setNRemaining(500);

        }


      }
      else {
        showErrorMsg("An error occured. Please try again!");
        return false;
      }
    }
  }

  const handleContactUsForm = async (event) => {
    event.preventDefault();

    try {


      //--start loader
      dispatch(rootAction.commonAction.setLoading(true));


      let isValid = false;
      let validationArray = [];


      isValid = validateAnyFormField('Name', FullName, 'text', null, 200, true);
      if (isValid == false) {
        validationArray.push({
          isValid: isValid
        });
      }


      isValid = validateAnyFormField('Email', Email, 'email', null, 100, true);
      if (isValid == false) {
        validationArray.push({
          isValid: isValid
        });
      }

      // isValid = validateAnyFormField('Phone Number', PhoneNumber, 'text', null, 20, true);
      // if (isValid == false) {
      //   validationArray.push({
      //     isValid: isValid
      //   });
      // }

      isValid = validateAnyFormField('Subject', Subject, 'text', null, 150, true);
      if (isValid == false) {
        validationArray.push({
          isValid: isValid
        });
      }

      isValid = validateAnyFormField('Message', Message, 'text', null, 2000, true);
      if (isValid == false) {
        validationArray.push({
          isValid: isValid
        });
      }



      //--check if any field is not valid
      if (validationArray != null && validationArray.length > 0) {

        isValid = false;
        return false;
      } else {
        isValid = true;
      }

      if (isValid) {
        setOtpModal(true);

      }



    } catch (err) {
      console.log(err);
      let error_occuredtext = TranslatedText(LocalizationLabelsArray,"An error occurred. Please try again!", "lbl_contact_error_occurred")
      showErrorMsg(error_occuredtext);

      return false;

    } finally {
      //--stop loader
      setTimeout(() => {
        dispatch(rootAction.commonAction.setLoading(false));
      }, LOADER_DURATION);

    }
  }


  useEffect(() => {
    // declare the data fetching function
    const dataOperationInUseEffect = async () => {

      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["Index_Contact_Us"], null);
      if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }

    }
    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    // call the function
    dataOperationInUseEffect().catch(console.error);

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);
  }, [])


  return (
    <>

      <Helmet>
        <title>{siteTitle} - {TranslatedText(LocalizationLabelsArray, "Contact Us", "lbl_contact_title")}</title>
        <meta name="description" content={siteTitle + " - Contact Us"} />
        <meta name="keywords" content="Contact Us"></meta>
      </Helmet>

      <SiteBreadcrumb title={TranslatedText(LocalizationLabelsArray, "Contact Us", "lbl_contact_title")} parent="Home" />

      <section className="contact-page mt-3">
        <div className="custom-container">
          <h3 className="text-center mb-3">{TranslatedText(LocalizationLabelsArray, "Get in touch", "lbl_contact_get_touch")}</h3>
          <Row className="section-big-pb-space g-4">
            <Col xl="6">
              <Form className="theme-form" onSubmit={handleContactUsForm}>
                <div className="form-row row">
                  <Col md="6">
                    <FormGroup>

                      <Label htmlFor="FullName" id="lbl_cont_form_name">

                        {TranslatedText(LocalizationLabelsArray, "Name", "lbl_contact_name")}
                        <span className="required-field">*</span></Label>
                      <Input type="text" name="FullName" id="FullName" className="form-control"
                        required={true}
                        data-error="Please enter your name"
                        placeholder= {TranslatedText(LocalizationLabelsArray, "Enter your name", "lbl_contact_enter_name")}
                        value={FullName}
                        onChange={(e) => setFullName(e.target.value)}

                      />

                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>

                      <Label htmlFor="Email" id="lbl_cont_form_email">{TranslatedText(LocalizationLabelsArray, "Email", "lbl_contact_email")} <span className='required-field'>*</span></Label>
                      <Input type="email" name="Email" id="Email" className="form-control" required={true}
                        data-error={TranslatedText(LocalizationLabelsArray,"Please enter your email", "lbl_contact_please_enter_email")}
                        placeholder={TranslatedText(LocalizationLabelsArray,"Enter your Email Address", "lbl_contact_enter_email")}
                        value={Email}
                        onChange={(e) => setEmail(e.target.value)}

                      />

                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>

                      <Label htmlFor="PhoneNumber" id="lbl_cont_form_phon">{TranslatedText(LocalizationLabelsArray,"Phone Number", "lbl_cont_form_phon")}</Label>
                      <Input type="text" name="PhoneNumber" id="PhoneNumber" className="form-control"
                        required={false}
                        data-error={TranslatedText(LocalizationLabelsArray,"Please enter your phone number", "lbl_cont_form_please_enter_phon")}
                        placeholder={TranslatedText(LocalizationLabelsArray,"Enter your Phone Number", "lbl_cont_form_enter_phon")}
                        value={PhoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}

                      />


                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>

                      <Label htmlFor="Subject" id="lbl_cont_form_subj">{TranslatedText(LocalizationLabelsArray,"Subject", "lbl_contact_subject")} <span className='required-field'>*</span></Label>


                      <select className="form-control dropdown-toggle"
                        value={Subject}
                        onChange={(e) => setSubject(e.target.value)}>
                        <option value="Account">{TranslatedText(LocalizationLabelsArray,"Account", "lbl_contact_sub_account")}</option>
                        <option value="Order">{TranslatedText(LocalizationLabelsArray,"Order", "lbl_contact_sub_order")}</option>
                        <option value="Sales">{TranslatedText(LocalizationLabelsArray,"Sales", "lbl_contact_sub_sales")}</option>
                        <option value="Complain">{TranslatedText(LocalizationLabelsArray,"Complain", "lbl_contact_sub_complain")}</option>
                        <option value="Other">{TranslatedText(LocalizationLabelsArray,"Other", "lbl_contact_sub_other")}</option>
                      </select>


                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <div>

                      <Label htmlFor="message" id="lbl_cont_form_msg">{TranslatedText(LocalizationLabelsArray,"Your Message", "lbl_contact_message")} <span className='required-field'>*</span></Label>
                      <div className='mt-4'>
                        <textarea name="Message" id="message" cols="30" rows="8"
                          required={true}
                          data-error={TranslatedText(LocalizationLabelsArray,"Please enter your message", "lbl_contact_please_enter_message")} 
                          className="form-control"
                          placeholder={TranslatedText(LocalizationLabelsArray,"Enter your Message", "lbl_contact_enter_message")} 
                          maxlength="500"
                          value={Message}
                          onKeyDown={(e) => {
                            setNRemaining(500 - e.target.value.length)
                          }}
                          onChange={(e) => setMessage(e.target.value)}

                        />
                        <span>{TranslatedText(LocalizationLabelsArray,"Remaining", "lbl_contact_remaining")} : </span>
                        <span id='.remaining' style={{
                          fontWeight: 'bolder',
                          color: 'var(--theme-color1)'
                        }}>{NRemaining}</span>
                      </div>



                    </div>
                  </Col>
                  <Col md="12">
                    <button className="btn btn-normal" type="submit">
                    {TranslatedText(LocalizationLabelsArray,"Send Your Message", "lbl_contact_send_message")} 
                    </button>
                  </Col>
                </div>
              </Form>
            </Col >
            <Col xl="6" className="map">
              <div className="theme-card">

                <iframe title="Oriental Exotic SPRL - 44-46" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2519.3161406801128!2d4.327234476894445!3d50.84382975896158!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3c40b8192f1b1%3A0x923a419d36e0580!2sRue%20du%20Compas%2044%2C%201070%20Anderlecht!5e0!3m2!1sen!2sbe!4v1685189415604!5m2!1sen!2sbe" width="600" height="450"
                  style={{ "border": 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </Col>
          </Row >
          <Row></Row>
        </div >
      </section >

      <BestFacilities  />
      <ContactOtpModal
        show={OtpModal}
        LocalizationLabelsArray={LocalizationLabelsArray}
        onHide={() => {
          setOtpModal(false);
        }}
        email={Email}
        doAfterOTP={doAfterOTP}
      />
    </>
  );

}

export default ContactUs;
