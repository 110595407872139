import React, { Component, useContext, useEffect, useState } from 'react';
import MegaMenu from './MegaMenu';
import SearchHeader from './SearchHeader';
import TopHeader from './TopHeader';
import Announcement from './Announcement';
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen,replaceLoclizationLabel } from "../../../../helpers/CommonHelper";
import GlobalEnums from "../../../../helpers/GlobalEnums";

const Navbar = () => {
  const [HasClass, setHasClass] = useState("has0");
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

  const handleScroll = () => {
    let number = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 300) {
      if (window.innerWidth < 581) document.getElementById("stickyHeader").classList.remove("sticky");
      else document.getElementById("stickyHeader").classList.add("sticky");
    } else document.getElementById("stickyHeader").classList.remove("sticky");
  };

  useEffect(() => {
    // declare the data fetching function
    const dataOperationFunc = async () => {

      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["TopHeader"], null);
      if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    }
    // call the function
    dataOperationFunc().catch(console.error);
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header className={`${HasClass}`}>
        <div className="mobile-fix-option"></div>
        <div id="stickyHeader">
        <Announcement  setHasClass={setHasClass} />

        </div>
        <div className={`normal-header ${HasClass}`}>
        <TopHeader LocalizationLabelsArray={LocalizationLabelsArray} />

          <div className="layout-header2">
            <SearchHeader LocalizationLabelsArray={LocalizationLabelsArray}/>
          </div>
          <div className="category-header-2">
            <MegaMenu />
          </div>
        </div>

      </header>


    </>
  );

}


export default Navbar;
