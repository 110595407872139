import React from "react";
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from "../../../../helpers/CommonHelper";



const MenuContactUs = ({ spanClass, MegaMenuLocalizationLabelsArray }) => {
  return (
    <div className={`d-xxl-flex d-none ${spanClass !== "contact-item" ? "contact-block" : ""}`}>
      <div className="d-flex" style={{width: 'max-content'}}>
        <i className="fa fa-volume-control-phone"></i>
        <span className={spanClass}>
          {MegaMenuLocalizationLabelsArray.length > 0 ?
            replaceLoclizationLabel(MegaMenuLocalizationLabelsArray, "call us", "lbl_mgmenu_call_us")
            :
            "call us"
          }
          <span>+32 483-544.195</span>
        </span>
      </div>
    </div>
  );
};

export default MenuContactUs;
