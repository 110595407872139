import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate, NavLink } from 'react-router-dom';
import { Row, Col, Container, Input, Label, Collapse, Media } from "reactstrap";
import ProductRatingStars from "../products/ProductRatingStars";
import { MakeApiCallSynchronous, MakeApiCallAsync } from '../../../helpers/ApiHelpers';
import Config from '../../../helpers/Config';
import { useSelector, useDispatch } from 'react-redux';
import { AddProductToCart } from '../../../helpers/CartHelper';
import rootAction from '../../../stateManagment/actions/rootAction';
import { LOADER_DURATION } from '../../../helpers/Constants';
import { makeAnyStringLengthShort, makeProductShortDescription, replaceWhiteSpacesWithDashSymbolInUrl } from '../../../helpers/ConversionHelper';
import { GetDefaultCurrencySymbol, getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, TranslatedText } from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';


export const SiteLeftSidebarFilter = (props) => {
    const [adminPanelBaseURL, setBaseUrl] = useState(Config['ADMIN_BASE_URL']);

    const [isCategoryOpen, setIsCategoryOpen] = useState(true);
    const toggleCategory = () => setIsCategoryOpen(!isCategoryOpen);
    const [isBrandOpen, setIsBrandOpen] = useState(true);
    const [isSizeOpen, setIsSizeOpen] = useState(false);
    const [isRatingOpen, setIsRatingOpen] = useState(false);
    const toggleBrand = () => setIsBrandOpen(!isBrandOpen);
    const toggleSize = () => setIsSizeOpen(!isSizeOpen);
    const toggleRating = () => setIsRatingOpen(!isRatingOpen);
    const [isTagOpen, setIsTagOpen] = useState(false);
    const toggleTag = () => setIsTagOpen(!isTagOpen);

    const [isColorOpen, setIsColorOpen] = useState(true);
    const toggleColor = () => setIsColorOpen(!isColorOpen);
    const [isPriceOpen, setIsPriceOpen] = useState(false);
    const dispatch = useDispatch();
    const [SizeList, setSizeList] = useState([]);
    const [CategoriesList, setCategoriesList] = useState([]);
    const [ManufacturerList, setManufacturerList] = useState([]);
    const [TagsList, setTagsList] = useState([]);
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const [langCode, setLangCode] = useState('');
    const [openedCategoryId, setOpenedCategoryId] = useState(-1);




    const clearFilter = (e) => {

        window.location.reload();
    }


    useEffect(() => {

        const GetFiltersAllValues = async () => {


            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',

            }


            const param = {
                requestParameters: {
                    PageNo: 1,
                    PageSize: 100,
                    recordValueJson: "[]",
                },
            };

            //--Get categories list
            const categoriesResponse = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_CATEGORIES_LIST'], null, param, headers, "POST", true);
            if (categoriesResponse != null && categoriesResponse.data != null) {
                await setCategoriesList(JSON.parse(categoriesResponse.data.data));
                console.log(JSON.parse(categoriesResponse.data.data))

            }

            //--Get sizes list
            const sizeResponse = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_SIZE_LIST'], null, param, headers, "POST", true);
            if (sizeResponse != null && sizeResponse.data != null) {
                await setSizeList(JSON.parse(sizeResponse.data.data));
            }

            //--Get manufacturer list
            const manufacturerResponse = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_MANUFACTURER_LIST'], null, param, headers, "POST", true);
            if (manufacturerResponse != null && manufacturerResponse.data != null) {
                await setManufacturerList(JSON.parse(manufacturerResponse.data.data));

            }

            //--Get popular tags
            const tagsResponse = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_TAGS_LIST'], null, param, headers, "POST", true);
            if (tagsResponse != null && tagsResponse.data != null) {
                await setTagsList(JSON.parse(tagsResponse.data.data));

            }



        }

        //--start loader
        dispatch(rootAction.commonAction.setLoading(true));

        // call the function
        GetFiltersAllValues().catch(console.error);

        //--stop loader
        setTimeout(() => {
            dispatch(rootAction.commonAction.setLoading(false));
        }, LOADER_DURATION);
    }, [])

    useEffect(() => {
        // declare the data fetching function
        const dataOperationFunc = async () => {

            //--Get language code
            let lnCode = getLanguageCodeFromSession();
            await setLangCode(lnCode);

            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["SiteLeftSidebarFilter"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                await setLocalizationLabelsArray(arryRespLocalization);
            }
        }
        // call the function
        dataOperationFunc().catch(console.error);
    }, [])

const OpenEachCategory = (item)=>{
    if (openedCategoryId == item.CategoryID) {
        setOpenedCategoryId(-1)
    }else{
        setOpenedCategoryId(item.CategoryID);
        const value = CategoriesList.filter(obj => obj.ParentCategoryID == item.CategoryID).map((elementChild) => elementChild.CategoryID);
        props.setFilterValueInParent(null,value,"category");
    }
}


    return (
        <>

            <div className="creative-inner category-side" style={{
               paddingRight: '0.4rem'
            }}>

                <div className="collection-mobile-back">
                    <span className="filter-back"
                        onClick={(e) => {
                            props.setLeftSidebarOpenCloseFromFilter(e, false);
                        }}
                    >
                        <i className="fa fa-angle-left" aria-hidden="true"></i> back
                    </span>
                </div>

                {
                    CategoriesList != undefined && CategoriesList != null && CategoriesList.length > 0
                        ?
                        <div className="category-list w-100">

                            {CategoriesList?.map((item, idx) => {

                                if (CategoriesList.filter(obj => obj.ParentCategoryID == item.CategoryID).length > 0) {
                                    return (
                                        <div key={idx} style={{ marginTop: "15px" }}>
                                            <div className="collection-collapse-block open w-100">

                                                <h4 className="collapse-block-title mt-0 w-100" onClick={() => {
                                                  OpenEachCategory(item);
                                                }}>
                                                      <img style={{border: ' 1px solid ' +item.HexCode, padding:"2px"}} className=" rounded-5" src={adminPanelBaseURL + item.AttachmentURL} width={35} />
                                                    <span className="m-r-2">
                                                        {

                                                            langCode != null && langCode == Config.LANG_CODES_ENUM["Arabic"]
                                                                ?
                                                                (item.LocalizationJsonData != null && item.LocalizationJsonData.length > 0
                                                                    ?
                                                                    makeAnyStringLengthShort(item.LocalizationJsonData?.find(l => l.langId == Config.LANG_CODES_IDS_ENUM["Arabic"])?.text, 30)
                                                                    :
                                                                    makeAnyStringLengthShort(item.Name, 30)
                                                                )

                                                                :
                                                                makeAnyStringLengthShort(item.Name, 30)
                                                        }
                                                    </span>

                                                </h4>

                                                <Collapse isOpen={item.CategoryID === openedCategoryId}>
                                                    <div className="collection-collapse-block hide">
                                                        <div style={{ marginLeft: '3rem', fontSize: "14px", lineHeight: "14px" }}>
                                                            {CategoriesList.filter(obj => obj.ParentCategoryID == item.CategoryID).map((elementChild, idxChild) => {


                                                                return (
                                                                    <div key={idxChild} className="custom-control custom-checkbox collection-filter-checkbox" >
                                                                        <NavLink to={"#"}  onClick={(e) => { e.preventDefault(); props.setFilterValueInParent(e, elementChild.CategoryID, "subcategory"); }} className={`custom-control-label ${props.selectedCategory === elementChild.CategoryID?"actived":""}`}>
                                                                            {

                                                                                langCode != null && langCode == Config.LANG_CODES_ENUM["Arabic"]
                                                                                    ?
                                                                                    (elementChild.LocalizationJsonData != null && elementChild.LocalizationJsonData.length > 0
                                                                                        ?
                                                                                        makeAnyStringLengthShort(elementChild.LocalizationJsonData?.find(l => l.langId == Config.LANG_CODES_IDS_ENUM["Arabic"])?.text, 30)
                                                                                        :
                                                                                        makeAnyStringLengthShort(elementChild.Name, 30)
                                                                                    )

                                                                                    :
                                                                                    makeAnyStringLengthShort(elementChild.Name, 30)
                                                                            }
                                                                        </NavLink>
                                                                    </div>
                                                                );
                                                            })}

                                                        </div>
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return null;
                                }


                            })}


                        </div>
                        :
                        <>

                        </>

                }

                {
                    ManufacturerList != undefined && ManufacturerList != null && ManufacturerList.length > 0
                        ?
                        <div className="collection-collapse-block open" style={{marginTop:"1rem"}}>
                            <h3 className="collapse-block-title mt-0" onClick={toggleBrand}>
                                {TranslatedText(LocalizationLabelsArray, "Brands", "lbl_lftfilt_brand")}
                            </h3>
                            <Collapse isOpen={isBrandOpen}>
                                <div className="collection-collapse-block-content">
                                    <div className="collection-brand-filter">


                                        {ManufacturerList &&
                                            ManufacturerList?.slice(0, 10)?.map((item, idx) =>


                                                <div className="custom-control custom-checkbox collection-filter-checkbox" >
                                                    <Input
                                                        onChange={(e) => {
                                                            props.setFilterValueInParent(e, item.ManufacturerID, "brand");

                                                        }}
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id={`brad_${idx}`}
                                                    />
                                                    <label className="custom-control-label">{item.Name}</label>
                                                </div>


                                            )}



                                    </div>
                                </div>
                            </Collapse>
                        </div>

                        :

                        <>
                        </>
                }

                {
                    SizeList != undefined && SizeList != null && SizeList.length > 0
                        ?
                        <div className="collection-collapse-block open">
                            <h3 className="collapse-block-title mt-0" onClick={toggleSize}>
                                {TranslatedText(LocalizationLabelsArray, "Size", "lbl_lftfilt_size")}
                            </h3>
                            <Collapse isOpen={isSizeOpen}>
                                <div className="collection-collapse-block-content">
                                    <div className="collection-brand-filter">


                                        {SizeList &&
                                            SizeList?.slice(0, 10)?.map((item, idx) =>


                                                <div className="custom-control custom-checkbox collection-filter-checkbox" >
                                                    <Input
                                                        onChange={(e) => {
                                                            props.setFilterValueInParent(e, item.SizeID, "size");

                                                        }}
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id={`size_${idx}`}
                                                    />
                                                    <label className="custom-control-label">
                                                        {

                                                            langCode != null && langCode == Config.LANG_CODES_ENUM["Arabic"]
                                                                ?
                                                                (item.LocalizationJsonData != null && item.LocalizationJsonData.length > 0
                                                                    ?
                                                                    makeAnyStringLengthShort(item.LocalizationJsonData?.find(l => l.langId == Config.LANG_CODES_IDS_ENUM["Arabic"])?.text, 30)
                                                                    :
                                                                    makeAnyStringLengthShort(item.ShortName, 30)
                                                                )

                                                                :
                                                                makeAnyStringLengthShort(item.ShortName, 30)
                                                        }
                                                    </label>
                                                </div>


                                            )}



                                    </div>
                                </div>
                            </Collapse>
                        </div>

                        :

                        <>
                        </>
                }

                {
                    //PriceValuesArray != undefined && PriceValuesArray != null && PriceValuesArray.length > 0
                    //    ?

                    //    <div className="collection-collapse-block border-0 open">
                    //        <h3 className="collapse-block-title" onClick={togglePrice}>
                    //            {TranslatedText(LocalizationLabelsArray, "Price", "lbl_lftfilt_price")}
                    //        </h3>
                    //        <Collapse isOpen={isPriceOpen}>
                    //            <div className="collection-collapse-block-content">
                    //                <div className="collection-brand-filter">

                    //                    {
                    //                        PriceValuesArray?.map((item, idx) =>

                    //                            <div className="custom-control custom-checkbox collection-filter-checkbox">
                    //                                <Input
                    //                                    onClick={(e) => {
                    //                                        props.setFilterValueInParent(e, item.id, "price");

                    //                                    }}
                    //                                    type="radio"
                    //                                    name="price-filter"
                    //                                    className="custom-control-input"
                    //                                    id="hundred"
                    //                                    checked={radioChecked === item.id}
                    //                                    onChange={() => setRadioChecked(item.id)}
                    //                                />
                    //                                <Label className="custom-control-label">{item.name}</Label>
                    //                            </div>
                    //                        )}


                    //                </div>
                    //            </div>
                    //        </Collapse>
                    //    </div>
                    //    :
                    //    <>
                    //    </>
                }

                {/*<div className="collection-collapse-block open">*/}
                {/*    <h3 className="collapse-block-title mt-0" onClick={toggleRating}>*/}
                {/*        {TranslatedText(LocalizationLabelsArray, "Rating", "lbl_lftfilt_rating")}*/}
                {/*    </h3>*/}
                {/*    <Collapse isOpen={isRatingOpen}>*/}
                {/*        <div className="collection-collapse-block-content">*/}
                {/*            <div className="collection-brand-filter">*/}
                {/*                <div className="custom-control custom-checkbox collection-filter-checkbox" >*/}
                {/*                    <Link to="#"*/}
                {/*                        onClick={(e) => { props.setFilterValueInParent(e, 5, "rating"); }}>*/}
                {/*                        <ProductRatingStars Rating={5} />*/}
                {/*                    </Link>*/}
                {/*                </div>*/}
                {/*                <div className="custom-control custom-checkbox collection-filter-checkbox" >*/}
                {/*                    <Link to="#"*/}
                {/*                        onClick={(e) => { props.setFilterValueInParent(e, 4, "rating"); }}*/}
                {/*                    >*/}
                {/*                        <ProductRatingStars Rating={4} />*/}
                {/*                    </Link>*/}
                {/*                </div>*/}
                {/*                <div className="custom-control custom-checkbox collection-filter-checkbox" >*/}
                {/*                    <Link to="#"*/}
                {/*                        onClick={(e) => { props.setFilterValueInParent(e, 3, "rating"); }}*/}
                {/*                    >*/}
                {/*                        <ProductRatingStars Rating={3} />*/}
                {/*                    </Link>*/}
                {/*                </div>*/}
                {/*                <div className="custom-control custom-checkbox collection-filter-checkbox" >*/}
                {/*                    <Link to="#"*/}
                {/*                        onClick={(e) => { props.setFilterValueInParent(e, 2, "rating"); }}*/}
                {/*                    >*/}
                {/*                        <ProductRatingStars Rating={2} />*/}
                {/*                    </Link>*/}
                {/*                </div>*/}

                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </Collapse>*/}
                {/*</div>*/}


                {
                    TagsList != undefined && TagsList != null && TagsList.length > 0
                        ?
                        <div className="collection-collapse-block open">
                            <h3 className="collapse-block-title mt-0" onClick={toggleTag}>
                                {TranslatedText(LocalizationLabelsArray, "Popular Tags", "lbl_lftfilt_tags")}
                            </h3>
                            <Collapse isOpen={isTagOpen}>
                                <div className="collection-collapse-block-content">
                                    <div className="collection-brand-filter">


                                        {TagsList &&
                                            TagsList?.slice(0, 10)?.map((item, idx) =>


                                                <div className="custom-control custom-checkbox collection-filter-checkbox" >
                                                    <Input
                                                        onChange={(e) => {
                                                            props.setFilterValueInParent(e, item.TagID, "tag");

                                                        }}
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id={`tag_${idx}`}
                                                    />
                                                    <label className="custom-control-label">
                                                        {

                                                            langCode != null && langCode == Config.LANG_CODES_ENUM["Arabic"]
                                                                ?
                                                                (item.LocalizationJsonData != null && item.LocalizationJsonData.length > 0
                                                                    ?
                                                                    makeAnyStringLengthShort(item.LocalizationJsonData?.find(l => l.langId == Config.LANG_CODES_IDS_ENUM["Arabic"])?.text, 30)
                                                                    :
                                                                    makeAnyStringLengthShort(item.TagName, 30)
                                                                )

                                                                :
                                                                makeAnyStringLengthShort(item.TagName, 30)
                                                        }
                                                    </label>
                                                </div>


                                            )}



                                    </div>
                                </div>
                            </Collapse>
                        </div>

                        :

                        <>
                        </>
                }





                <Row>
                    <Col xs="12" md="12" lg="12" className="text-center">
                        <Link
                            className="btn btn-normal btn-block"
                            onClick={(e) => {
                                clearFilter(e);

                            }}
                        >
                            Clear All Filter
                        </Link>
                    </Col>
                </Row>



            </div>

            {/* <LeftSideBarPopularProducts /> */}


        </>


    );
};


export const LeftSideBarPopularProducts = () => {
    const dispatch = useDispatch();

    const [PopularProductsList, setPopularProductsList] = useState([]);
    const [adminPanelBaseURL, setBaseUrl] = useState(Config['ADMIN_BASE_URL']);
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

    const GetPopularProductsForLeftSideBar = async () => {

        const headersPouplarProducts = {
            // customerid: userData?.UserID,
            // customeremail: userData.EmailAddress,
            Accept: 'application/json',
            'Content-Type': 'application/json',

        }


        const paramPouplarProducts = {
            requestParameters: {
                PageNo: 1,
                PageSize: 10,
                recordValueJson: "[]",
            },
        };

        const responsePopularProducts = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_POPULAR_PRODUCTS_LIST'], null, paramPouplarProducts, headersPouplarProducts, "POST", true);
        if (responsePopularProducts != null && responsePopularProducts.data != null) {
            await setPopularProductsList(JSON.parse(responsePopularProducts.data.data));
            console.log(JSON.parse(responsePopularProducts.data.data));
        }
    }

    const HandleAddToCart = (item) => {

        if (item == undefined || item.ProductId == undefined || item.ProductId < 1) {
            return false;
        }

        let defaultImage = (item?.ProductImagesJson?.length > 0) ? item.ProductImagesJson[0].AttachmentURL : '';
        let cartItems = AddProductToCart(item.ProductId, 1, item.Price, [], item.TotalAttributes, defaultImage);

        dispatch(rootAction.cartAction.setCustomerCart(cartItems));
        dispatch(rootAction.cartAction.SetTotalCartItems(JSON.parse(cartItems).length));

    }

    useEffect(() => {

        const GetFiltersAllValues = async () => {

            //--get popular products list
            await GetPopularProductsForLeftSideBar();

            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["SiteLeftSidebarFilter"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                await setLocalizationLabelsArray(arryRespLocalization);
            }

        }

        //--start loader
        dispatch(rootAction.commonAction.setLoading(true));

        // call the function
        GetFiltersAllValues().catch(console.error);

        //--stop loader
        setTimeout(() => {
            dispatch(rootAction.commonAction.setLoading(false));
        }, LOADER_DURATION);
    }, [])



    return (

        <div className="theme-card creative-card creative-inner">
            <h5 className="title-border">
                {TranslatedText(LocalizationLabelsArray, "Popular Products", "lbl_lftfilt_pop_prod")}

            </h5>
            <div className="offer-slider slide-1">
                <div>
                    {
                        PopularProductsList?.slice(0, 5)?.map((item, idx) =>
                            <div className="media">
                                {
                                    item?.ProductImagesJson?.slice(0, 1).map((img, imgIdx) =>
                                        <Link to={`/${getLanguageCodeFromSession()}/product-detail/${item.ProductId}/${replaceWhiteSpacesWithDashSymbolInUrl(item.CategoryName) ?? "shop"}/${replaceWhiteSpacesWithDashSymbolInUrl(item.ProductName)}`}
                                        >
                                            <Media className="img-fluid " src={adminPanelBaseURL + img.AttachmentURL} alt="" />
                                        </Link>

                                    )
                                }

                                <div className="media-body align-self-center">
                                    <ProductRatingStars Rating={item.Rating == undefined || item.Rating == null ? 5 : item.Rating} />

                                    <Link to={`/${getLanguageCodeFromSession()}/product-detail/${item.ProductId}/${replaceWhiteSpacesWithDashSymbolInUrl(item.CategoryName) ?? "shop"}/${replaceWhiteSpacesWithDashSymbolInUrl(item.ProductName)}`}
                                    >
                                        <h6>{makeProductShortDescription(item.ProductName, 20)}</h6>
                                    </Link>
                                    <div className="product-buttons">
                                        <Link
                                            to="#"
                                            className="btn btn-normal p-1 w-100"
                                            id="lbl_prd_det_addcart"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                HandleAddToCart(item);
                                            }}
                                        >
                                            {TranslatedText(LocalizationLabelsArray, "Add to Cart", "lbl_prd_det_add_to_request")}

                                        </Link>

                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </div>

    );
}


