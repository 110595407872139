import { Col, Row } from "reactstrap";
import { useEffect, useState } from "react";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import Config from "../../../helpers/Config";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import GlobalEnums from "../../../helpers/GlobalEnums";
import { getLanguageCodeFromSession, ScrollIntoSpecificDiv } from "../../../helpers/CommonHelper";

import rootAction from "../../../stateManagment/actions/rootAction";
import { LOADER_DURATION } from "../../../helpers/Constants";
import ProductBox from "../../components/products/ProductBox";
import ProductsFilterOptions from "../../components/products/ProductsFilterOptions";
import { Helmet } from 'react-helmet';
import emptySearch from '../../../resources/themeContent/images/empty-search.jpg';
import sideBannerImg from '../../../resources/themeContent/images/category/side-banner.png';
import SitePagination from "../../components/shared/SitePagination";
import { SiteLeftSidebarFilter } from "../../components/shared/SiteLeftSidebarFilter";


const BrandDetails = () => {
    const [leftSidebarOpen, setLeftSidebarOpen] = useState(false);
    const [cols, setCols] = useState("col-xl-3 col-md-4 col-6 col-grid-box");
    const [grid, setGrid] = useState(cols);
    const [layout, setLayout] = useState("");

    //--noor code starts here
    const dispatch = useDispatch();
    const search = useLocation().search;
    const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);
    const [adminPanelBaseURL, setBaseUrl] = useState(Config['ADMIN_BASE_URL']);

    const [ProductsList, setProductsList] = useState([]);
    const [TotalRecords, setTotalRecords] = useState(0);
    const [BrandInfo, setBrandInfo] = useState({
        AttachmentURL: "",
        BannerURL: "",
        Description: "",
        ManufacturerID: "",
        Name: ""
    });
    const [showPagination, setshowPagination] = useState(false);
    const [PageNo, setPageNo] = useState(1);
    const [PageSize, setPageSize] = useState(24);


    const params = useParams();
    let BrandId = -1;
    BrandId = (parseInt(params.brandId) ?? 0);
    let categParamArray = [];
    categParamArray.push(parseInt(params.category_id) ?? 0);
    const [CategoryID, setCategoryID] = useState(categParamArray);


   //--this function called from the ProductsFiltersOption component
   const setPageSizeFromProductFilter = async (e) => {

    setPageSize(parseInt(e.target.value));

}

    const getBrandDetails = async () => {

        let headersFromPage = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }


        let paramFromPage = {
            requestParameters: {
                ManufacturerID: BrandId,
                PageNo: PageNo,
                PageSize: PageSize,
                recordValueJson: "[]",

            },
        };
        let responseBrandDetails = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_BRAND_DETAIL'], null, paramFromPage, headersFromPage, "POST", true);
        if (responseBrandDetails != null && responseBrandDetails.data != null) {

            let BrandDetails = JSON.parse(responseBrandDetails.data.data)[0];
            setBrandInfo(BrandDetails);
            console.log(BrandDetails);
        }
    }


    const getAllProductsAfterAnyFilterChange = async (pageNumber, _categoryId, _manufacturerId) => {

        try {


            //--start loader
            dispatch(rootAction.commonAction.setLoading(true));

            await setPageNo(pageNumber);

            let headersFromPage = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }


            let paramFromPage = {
                requestParameters: {
                    SearchTerm: null,
                    SizeID: null,
                    ColorID: null,
                    CategoryID: "",
                    TagID: null,
                    ManufacturerID: BrandId,
                    MinPrice: null,
                    MaxPrice: null,
                    Rating: null,
                    OrderByColumnName: null,
                    PageNo: PageNo,
                    PageSize: PageSize,
                    recordValueJson: "[]",

                },
            };

            setshowPagination(false);


            let responseAllProducts = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_All_PRODUCTS'], null, paramFromPage, headersFromPage, "POST", true);
            if (responseAllProducts != null && responseAllProducts.data != null) {

                await setProductsList(JSON.parse(responseAllProducts.data.data));
                let AllProducts = JSON.parse(responseAllProducts.data.data);
                await setTotalRecords(parseInt(AllProducts[0]?.TotalRecords ?? 0))
                console.log(JSON.parse(responseAllProducts.data.data));

                if (AllProducts.length > 0) {
                    await setshowPagination(true);
                }

            }


            //--stop loader
            setTimeout(() => {
                dispatch(rootAction.commonAction.setLoading(false));
            }, LOADER_DURATION);


        }
        catch (error) {
            console.error(error);
            //--stop loader
            setTimeout(() => {
                dispatch(rootAction.commonAction.setLoading(false));
            }, LOADER_DURATION);

        }

    }

    //--this function called from the SitePagination component
    const setCurrentPage = async (pageNumber) => {
        setTimeout(async () => {
            await getAllProductsAfterAnyFilterChange(pageNumber, null, null);
        }, 200);
    }
    useEffect(()=>{
        getAllProductsAfterAnyFilterChange(1, null, null);
    },[PageSize] )

    
    useEffect(() => {

        //--start loader
        dispatch(rootAction.commonAction.setLoading(true));
        getBrandDetails();
        getAllProductsAfterAnyFilterChange(1, "", BrandId);

        //--stop loader
        setTimeout(() => {
            dispatch(rootAction.commonAction.setLoading(false));
        }, LOADER_DURATION);

    }, [])
    //--noor code ends here

    return (
        <>
            <Helmet>
                <title>{BrandInfo.Name} - Brand</title>
                <meta name="description" content={BrandInfo.Name + " - Brand"} />
                <meta name="keywords" content="Brand"></meta>
            </Helmet>

            <div className="bg-light theme-slider slider-banner slide-banner-4 home-banner-bg">
                <div className="  brand-img home-banner-bg">
                    <img src={adminPanelBaseURL + BrandInfo.BannerURL} className="img-fluid" alt="slider" />

                </div>
            </div>
            <div id="st" className="banner-header py-2">
                <h2 className="fw-bold text-center text-light">{BrandInfo.Name}</h2>
                <div className="container">
                    <p className="text-white">
                        {BrandInfo.Description}
                    </p>
                </div>
            </div>
            <div className="d-flex flex-wrap p-4 pt-3 brand-detail">
                <SiteBreadcrumb title={BrandInfo.Name} parent="Brands" space={false} parent_link={`/${getLanguageCodeFromSession()}/brands`} />
                <div className="brand-product-page-per-view">
                            <select onChange={(e) => setPageSizeFromProductFilter(e)}>
                                <option value="8">8 Products Per Page</option>
                                <option value="16">16 Products Per Page</option>
                                <option value="24" selected>24 Products Per Page</option>
                                <option value="100">100 Products Per Page</option>
                                <option value="null">All Products</option>
                            </select>
                        </div>
                {/* Pagination */}
                <div className="">
                    <div className="theme-paggination-block">

                        <Row>
                            <Col xl="12" md="12" sm="12">
                                {
                                    showPagination == true ?
                                        <SitePagination
                                            TotalRecords={TotalRecords}
                                            CurrentPage={PageNo}
                                            PageSize={PageSize}
                                            setCurrentPage={setCurrentPage}
                                            PageRange={5}
                                        />

                                        :
                                        <>
                                        </>
                                }

                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <section className="mt-3 ratio_asos section-big-pb-space">
                <div className="collection-wrapper">
                    <div className="custom-container">
                        <Row>
                            {/* <Collection cols="col-xl-3 col-md-4 col-6 col-grid-box" layoutList="" /> */}
                            <Col className="collection-content">
                                <div className="page-main-content">
                                    <Row>
                                        <Col sm="12">

                                            <div className="collection-product-wrapper">
                                                {/* Product Grid */}
                                            <div className={`product-wrapper-grid ${layout}`}>
                                                    <Row>
                                                        {/* Product Box */}
                                                        {!ProductsList || ProductsList.length == 0 ? (
                                                            <Col xs="12">
                                                                <div>
                                                                    <div className="col-sm-12 empty-cart-cls text-center">
                                                                        <h3>
                                                                            <strong>No Product found</strong>
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        ) : (
                                                            ProductsList &&
                                                            ProductsList?.map((itm, i) => (
                                                                <div className={grid} key={i}>
                                                                    <div className="product">
                                                                        <div>

                                                                            <ProductBox
                                                                                hoverEffect={""}
                                                                                item={itm}
                                                                                layout={layout}
                                                                                ProductDetailPageForceUpload={false}

                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        )}
                                                    </Row>
                                                </div>

                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        {/* Pagination */}
                        <div className="d-flex p-4 pt-3 brand-detail">
                            <div className="w-75"></div>
                            <div className="theme-paggination-block">

                                <Row>
                                    <Col xl="12" md="12" sm="12">
                                        {
                                            showPagination == true ?
                                                <SitePagination
                                                    TotalRecords={TotalRecords}
                                                    CurrentPage={PageNo}
                                                    PageSize={PageSize}
                                                    setCurrentPage={setCurrentPage}
                                                    PageRange={5}
                                                />

                                                :
                                                <>
                                                </>
                                        }

                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>

                </div>
            </section>



        </>
    );
};

export default BrandDetails;
