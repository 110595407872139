import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { MakeApiCallAsync } from '../../../helpers/ApiHelpers';
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, TranslatedText } from '../../../helpers/CommonHelper';
import Config from '../../../helpers/Config';
import { LOADER_DURATION } from '../../../helpers/Constants';
import GlobalEnums from '../../../helpers/GlobalEnums';
import { showErrorMsg, validateAnyFormField } from '../../../helpers/ValidationHelper';
import rootAction from '../../../stateManagment/actions/rootAction';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import RegisterOtpModal from '../../components/modal/RegisterOtpModal';
import RegisterSuccessModal from '../../components/modal/RegisterSuccessModal';

const Signup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [CompanyName, setCompanyName] = useState('');
    const [VAT, setVAT] = useState('');
    const [isValidVat, setIsValidVat] = useState(false);
    const [EmailAddress, setEmailAddress] = useState('');
    const [Password, setPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');
    const [MobileNo, setMobileNo] = useState('');
    const [AddressLineOne, setAddressLineOne] = useState('');
    const [StateProvinceId, setStateProvinceId] = useState('');
    const [CityId, setCityId] = useState('');
    const [PostalCode, setPostalCode] = useState('');
    const [CountryID, setCountryID] = useState('');
    const [CountryText, setCountryText] = useState('');
    const [CountriesList, setCountriesList] = useState([]);
    const [StatesProvincesList, setStatesProvincesList] = useState([]);
    const [CitiesList, setCitiesList] = useState([]);
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const [OtpModal, setOtpModal] = useState(false);
    const [SuccessModal, setSuccessModal] = useState(false);
    const [SupportMobile, setSupportMobile] = useState("+32 465 31 67 27");



    const HandleStateProvinceChagne = async (value) => {
        if (value != undefined) {
            await setStateProvinceId(value);

            //--load city data when state changed
            await LoadCitiesData(value);
        }

    }

    const HandleCountryChange = async (value, text) => {
        if (value != undefined) {
            await setCountryID(value);
            setCountryText(text);
            CheckVat(VAT, text);
            //--load state province data
            await LoadStateProvinceData(value);
        }

    }

    const LoadCitiesData = async (StateValue) => {
        const headersCity = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }


        const paramCity = {
            requestParameters: {
                StateProvinceId: StateValue ?? StateProvinceId,
                recordValueJson: "[]",
            },
        };

        //--Get cities list
        const responseCities = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_CITIES_LIST'], null, paramCity, headersCity, "POST", true);
        if (responseCities != null && responseCities.data != null) {
            await setCitiesList(JSON.parse(responseCities.data.data));

        }
    }

    const LoadStateProvinceData = async (CountryValue) => {
        const headersStateProvince = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }


        const paramStateProvince = {
            requestParameters: {
                CountryId: CountryValue ?? CountryID,
                recordValueJson: "[]",
            },
        };

        //--Get state province list
        const responseStatesProvince = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_STATES_PROVINCES_LIST'], null, paramStateProvince, headersStateProvince, "POST", true);
        if (responseStatesProvince != null && responseStatesProvince.data != null) {
            await setStatesProvincesList(JSON.parse(responseStatesProvince.data.data));

        }
    }
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }

    const doAfterOTP = async () => {

        const param = {
            requestParameters: {
                FirstName: FirstName,
                LastName: LastName,
                CompanyName: CompanyName,
                VAT: VAT,
                EmailAddress: EmailAddress,
                Password: Password,
                MobileNo: MobileNo,
                AddressLineOne: AddressLineOne,
                CityId: CityId ?? -999,
                StateProvinceId: StateProvinceId ?? -999,
                PostalCode: PostalCode,
                CountryID: CountryID ?? -999,
            },
        };

        //--make api call for data operation
        const response = await MakeApiCallAsync(Config.END_POINT_NAMES['SIGNUP_USER'], null, param, headers, "POST", true);
        if (response != null && response.data != null) {
            let userData = JSON.parse(response.data.data);
            if (userData.length > 0 && userData[0].ResponseMsg != undefined && userData[0].ResponseMsg == "Saved Successfully") {

                const param2 = {
                    requestParameters: {
                        Email: EmailAddress
                    },
                };

                const response2 = await MakeApiCallAsync(Config.END_POINT_NAMES['SEND_REGISTERED_EMAIL'], Config['COMMON_CONTROLLER_SUB_URL'], param2, headers, "POST", true);
                if (response2 != null && response2.data != null) {
                    let admin_mobile = JSON.parse(response2.data.data).mobile;
                    setOtpModal(false);
                    setSupportMobile(admin_mobile);
                    setSuccessModal(true);

                }

            } else if (userData.length > 0 && userData[0].ResponseMsg != undefined && userData[0].ResponseMsg == "Email already exists!") {

                let error_text = TranslatedText(LocalizationLabelsArray, "Email already exists!", "lbl_signup_email_exists");
                showErrorMsg(error_text);

                return false;
            } else {

                let error_text = TranslatedText(LocalizationLabelsArray, "An error occurred. Please try again!", "lbl_signup_error_occured");
                showErrorMsg(error_text);

                return false;
            }
        }
    }
    const handleSignupForm = async (event) => {
        event.preventDefault();
        let isValid = false;
        let validationArray = [];
        if (isValidVat == false) {
            validationArray.push({
                isValid: isValidVat
            });
        }
        try {
            isValid = validateAnyFormField('First Name', FirstName, 'text', null, 200, true);
            if (isValid == false) {
                validationArray.push({
                    isValid: isValid
                });
            }


            isValid = validateAnyFormField('Last Name', LastName, 'text', null, 150, true);
            if (isValid == false) {
                validationArray.push({
                    isValid: isValid
                });
            }
            isValid = validateAnyFormField('Company Name', CompanyName, 'text', null, 150, true);
            if (isValid == false) {
                validationArray.push({
                    isValid: isValid
                });
            }
            isValid = validateAnyFormField('VAT/TVA Number', VAT, 'text', null, 20, true);
            if (isValid == false) {
                validationArray.push({
                    isValid: isValid
                });
            }

            isValid = validateAnyFormField('Email', EmailAddress, 'email', null, 150, true);
            if (isValid == false) {
                validationArray.push({
                    isValid: isValid
                });
            }

            isValid = validateAnyFormField('Password', Password, 'password', null, 150, true);
            if (isValid == false) {
                validationArray.push({
                    isValid: isValid
                });
            }

            isValid = validateAnyFormField('Confirm Password', ConfirmPassword, 'password', null, 150, true);
            if (isValid == false) {
                validationArray.push({
                    isValid: isValid
                });
            }

            isValid = validateAnyFormField('Country', CountryID, 'text', null, 150, true);
            if (isValid == false) {
                validationArray.push({
                    isValid: isValid
                });
            }

            isValid = validateAnyFormField('State', StateProvinceId, 'text', null, 150, true);
            if (isValid == false) {
                validationArray.push({
                    isValid: isValid
                });
            }
            isValid = validateAnyFormField('City', CityId, 'text', null, 150, true);
            if (isValid == false) {
                validationArray.push({
                    isValid: isValid
                });
            }
            isValid = validateAnyFormField('Shipping address', AddressLineOne, 'text', null, 600, true);
            if (isValid == false) {
                validationArray.push({
                    isValid: isValid
                });
            }


            //--check if any field is not valid
            if (validationArray != null && validationArray.length > 0) {

                isValid = false;
                return false;
            } else {
                isValid = true;
            }


            //--check if password equals
            if (Password != ConfirmPassword) {
                let error_text = TranslatedText(LocalizationLabelsArray, "Password does not match!", "lbl_login_pass_not_matched");
                showErrorMsg(error_text);
                isValid = false;
            }
            //--check Email and VAT

            const val_param = {
                requestParameters: {
                    VAT: VAT,
                    EmailAddress: EmailAddress,
                    recordValueJson: "[]",
                },
            };


            const response_val = await MakeApiCallAsync(Config.END_POINT_NAMES['VALIDATE_EMAIL_VAT'], null, val_param, headers, "POST", true);
            if (response_val != null && response_val.data != null) {
                let val_output = JSON.parse(response_val.data.data)[0].ResponseMsg;
                if (val_output !== "Success") {
                    showErrorMsg(val_output);
                    isValid = false;
                }
            }

            if (isValid) {

                setOtpModal(true);

            }



        } catch (err) {
            console.log(err);

            let error_text = TranslatedText(LocalizationLabelsArray, "An error occurred. Please try again!", "lbl_signup_error_occured");
            showErrorMsg(error_text);


            return false;

        } finally {
            //--stop loader
            setTimeout(() => {
                dispatch(rootAction.commonAction.setLoading(false));
            }, LOADER_DURATION);

        }
    }

    async function CheckVat(vatText, countrytext = CountryText) {
        let countruy = countrytext.split("-")[0];

        if (vatText.length > 7 && countruy.length > 0) {

            const VatResponse = await MakeApiCallAsync(Config.END_POINT_NAMES["GET_VAT_INFO"] + "/" + countruy + "/" + vatText, Config['COMMON_CONTROLLER_SUB_URL'], null, headers, "GET", true);
            if (VatResponse != null && VatResponse.data != null) {
                document.querySelector(".valid-form").classList.remove("fail")
                document.querySelector(".valid-form").classList.remove("success")
                const vatinfo = JSON.parse(VatResponse.data);
                setIsValidVat(vatinfo.isValid);
                if (vatinfo.isValid) {
                    setCompanyName(vatinfo.name);
                    setAddressLineOne(vatinfo.address);
                    document.querySelector(".valid-form").classList.add("success");
                } else {
                    document.querySelector(".valid-form").classList.add("fail");
                }

            }
        }
    }

    useEffect(() => {
        // declare the data fetching function
        const dataOperationInUseEffect = async () => {

            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }


            const paramCountry = {
                requestParameters: {

                    recordValueJson: "[]",
                },
            };


            //--Get countreis list
            const responseCountries = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_COUNTRIES_LIST'], null, paramCountry, headers, "POST", true);
            if (responseCountries != null && responseCountries.data != null) {
                await setCountriesList(JSON.parse(responseCountries.data.data));

            }

            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["Signup"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                await setLocalizationLabelsArray(arryRespLocalization);
            }


        }

        //--start loader
        dispatch(rootAction.commonAction.setLoading(true));

        // call the function
        dataOperationInUseEffect().catch(console.error);

        //--stop loader
        setTimeout(() => {
            dispatch(rootAction.commonAction.setLoading(false));
        }, LOADER_DURATION);


    }, [])


    return (
        <>
            <Helmet>
                <title>{siteTitle} -  {TranslatedText(LocalizationLabelsArray, "Sign Up", "lbl_singup_text")}</title>
                <meta name="description" content={siteTitle + " - Sign Up"} />
                <meta name="keywords" content="Sign Up"></meta>
            </Helmet>
            <SiteBreadcrumb title={TranslatedText(LocalizationLabelsArray, "Sign Up", "lbl_singup_text")} parent="Home" />


            <section className="login-page section-big-py-space">
                <div className="custom-container">


                    <Row className="row">
                        <Col xl="8" lg="8" md="8" className="offset-xl-2 offset-lg-2 offset-md-2">
                            <div className="theme-card">
                                <h3 className="text-center">

                                    {TranslatedText(LocalizationLabelsArray, "Create an Account", "lbl_singup_crtaccount")}


                                </h3>
                                <Form className="theme-form" onSubmit={handleSignupForm}>
                                    <div className="form-row row">
                                        <Col md="6">
                                            <FormGroup>

                                                <Label htmlFor="FirstName">
                                                    {TranslatedText(LocalizationLabelsArray, "First Name", "lbl_singup_fname")}
                                                    <span className="required-field">*</span></Label>
                                                <Input type="text" className="form-control" placeholder={TranslatedText(LocalizationLabelsArray, "Enter first name", "lbl_singup_enter_fname")} id="FirstName" name="FirstName"
                                                    required={true}
                                                    value={FirstName}
                                                    autoComplete='off'
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                />

                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <Label htmlFor="LastName">
                                                    {TranslatedText(LocalizationLabelsArray, "Last Name", "lbl_singup_lname")}
                                                    <span className="required-field">*</span></Label>
                                                <Input type="text" className="form-control" placeholder={TranslatedText(LocalizationLabelsArray, "Enter last name", "lbl_singup_enter_lname")} id="LastName" name="LastName"
                                                    required={true}
                                                    value={LastName}
                                                    autoComplete='off'
                                                    onChange={(e) => setLastName(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>

                                                <Label htmlFor="EmailAddress">
                                                    {TranslatedText(LocalizationLabelsArray, "Email", "lbl_singup_email")}
                                                    <span className="required-field">*</span></Label>
                                                <Input type="email" className="form-control" placeholder={TranslatedText(LocalizationLabelsArray, "Enter email", "lbl_singup_enter_email")} id="EmailAddress" name="EmailAddress"
                                                    required={true}
                                                    value={EmailAddress}
                                                    autoComplete='off'
                                                    onChange={(e) => setEmailAddress(e.target.value)}
                                                />



                                            </FormGroup>
                                        </Col>

                                        <Col md="6" className="select_input">
                                            <FormGroup>
                                                <Label htmlFor="CountryID">
                                                    {TranslatedText(LocalizationLabelsArray, "Country", "lbl_singup_country")}
                                                    <span className="required-field">*</span></Label>
                                                <select
                                                    className="form-control"
                                                    name="CountryID"
                                                    id="CountryID"
                                                    autoComplete='off'
                                                    required={true}
                                                    value={CountryID}
                                                    onChange={(e) => HandleCountryChange(e.target.value, e.target.options[e.target.selectedIndex].text)}
                                                >
                                                    <option value="-999">{TranslatedText(LocalizationLabelsArray, "Select Country", "lbl_singup_select_country")}</option>
                                                    {
                                                        CountriesList?.map((item, idx) =>

                                                            <option key={idx} value={item.CountryID}>{item.CountryName}</option>

                                                        )}


                                                </select>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup >

                                                <Label htmlFor="VAT">
                                                    {TranslatedText(LocalizationLabelsArray, "VAT/TVA Number", "lbl_singup_vat")}
                                                    <span className="required-field">*</span></Label>
                                                <div className="valid-form">
                                                    <Input type="text" className="form-control" placeholder={TranslatedText(LocalizationLabelsArray, "Enter VAT/TVA Number", "lbl_singup_enter_vat")} id="VAT" name="VAT"
                                                        required={true}
                                                        value={VAT}
                                                        autoComplete='off'
                                                        onChange={(e) => { CheckVat(e.target.value); setVAT(e.target.value) }}
                                                    />
                                                    <i className="fa fa-check-circle"></i>
                                                    <i className="fa fa-times-circle"></i>
                                                </div>


                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>

                                                <Label htmlFor="CompanyName">
                                                    {TranslatedText(LocalizationLabelsArray, "Company Name", "lbl_singup_cname")}
                                                    <span className="required-field">*</span></Label>
                                                <Input type="text" className="form-control" placeholder={TranslatedText(LocalizationLabelsArray, "Enter Company Name", "lbl_singup_enter_cname")} id="CompanyName" name="CompanyName"
                                                    required={true}
                                                    value={CompanyName}
                                                    autoComplete='off'
                                                    onChange={(e) => setCompanyName(e.target.value)}
                                                />

                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <Label htmlFor="StateProvinceId">
                                                    {TranslatedText(LocalizationLabelsArray, "State/Province", "lbl_singup_province")}
                                                    <span className="required-field">*</span></Label>

                                                <select
                                                    className="form-control"
                                                    name="StateProvinceId"
                                                    id="StateProvinceId"
                                                    required={true}
                                                    autoComplete='off'
                                                    value={StateProvinceId}
                                                    onChange={(e) => HandleStateProvinceChagne(e.target.value)}
                                                >
                                                    <option value="-999">{TranslatedText(LocalizationLabelsArray, "Select State", "lbl_singup_select_state")}</option>
                                                    {
                                                        StatesProvincesList?.map((item, idx) =>

                                                            <option key={idx} value={item.StateProvinceID}>{item.StateName}</option>

                                                        )}


                                                </select>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <Label htmlFor="CityId">
                                                    {TranslatedText(LocalizationLabelsArray, "City", "lbl_singup_city")}
                                                    <span className="required-field">*</span></Label>

                                                <select
                                                    className="form-control"
                                                    name="CityId"
                                                    id="CityId"
                                                    required={true}
                                                    autoComplete='off'
                                                    value={CityId}
                                                    onChange={(e) => setCityId(e.target.value)}
                                                >
                                                    <option value="-999">  {TranslatedText(LocalizationLabelsArray, "Select City", "lbl_singup_select_city")}</option>
                                                    {
                                                        CitiesList?.map((item, idx) =>

                                                            <option key={idx} value={item.CityID}>{item.CityName}</option>

                                                        )}


                                                </select>

                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>

                                                <Label htmlFor="MobileNo">
                                                    {TranslatedText(LocalizationLabelsArray, "Contact No", "lbl_singup_contact")}
                                                </Label>
                                                <Input type="text" className="form-control" placeholder={TranslatedText(LocalizationLabelsArray, "Enter contact no", "lbl_singup_enter_contact")} id="MobileNo" name="MobileNo"
                                                    value={MobileNo}
                                                    autoComplete='off'
                                                    onChange={(e) => setMobileNo(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <Label htmlFor="PostalCode">
                                                    {TranslatedText(LocalizationLabelsArray, "Postal Code", "lbl_singup_pcode")}
                                                </Label>
                                                <Input type="number" className="form-control" placeholder={TranslatedText(LocalizationLabelsArray, "Enter postal code", "lbl_singup_enter_pcode")} id="PostalCode" name="PostalCode"
                                                    value={PostalCode}
                                                    autoComplete='off'
                                                    onChange={(e) => setPostalCode(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <Label htmlFor="Password">
                                                    {TranslatedText(LocalizationLabelsArray, "Password", "lbl_singup_password")}
                                                    <span className="required-field">*</span>
                                                </Label>
                                                <Input type="password" className="form-control" placeholder={TranslatedText(LocalizationLabelsArray, "Enter your password", "lbl_singup_enter_password")} id="Password" name="Password"
                                                    required={true}
                                                    value={Password}
                                                    autoComplete='off'
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <Label htmlFor="ConfirmPassword">
                                                    {TranslatedText(LocalizationLabelsArray, "Confirm Password", "lbl_singup_confpassword")}
                                                    <span className="required-field">*</span>
                                                </Label>
                                                <Input type="password" className="form-control" placeholder={TranslatedText(LocalizationLabelsArray, "Confirm Password", "lbl_singup_confpassword")} id="ConfirmPassword" name="ConfirmPassword"
                                                    required={true}
                                                    autoComplete='off'
                                                    value={ConfirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col >
                                            <FormGroup>
                                                <Label htmlFor="AddressLineOne">
                                                    {TranslatedText(LocalizationLabelsArray, "Shipping Address", "lbl_singup_shippa")}
                                                    <span className="required-field">*</span></Label>
                                                <Input type="text" className="form-control w-100" placeholder={TranslatedText(LocalizationLabelsArray, "Enter shipping address", "lbl_singup_enter_shippa")} id="AddressLineOne" name="AddressLineOne"
                                                    required={true}
                                                    value={AddressLineOne}
                                                    autoComplete='off'
                                                    onChange={(e) => setAddressLineOne(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="12">
                                            <button className="btn btn-sm btn-normal mb-3" type="submit" id="lbl_singup_submitbnt">
                                                {TranslatedText(LocalizationLabelsArray, "Create Account", "lbl_singup_submitbnt")}
                                            </button>


                                        </Col>

                                        <Col md="12">
                                            <p>
                                                {TranslatedText(LocalizationLabelsArray, "Already have an account?", "lbl_singup_already_acc")}{" "}


                                                <Link to={`/${getLanguageCodeFromSession()}/login`} className="txt-default" id="lbl_singup_loginacnt">
                                                    {TranslatedText(LocalizationLabelsArray, "Login", "lbl_singup_loginacnt")}
                                                </Link>
                                            </p>
                                        </Col>
                                    </div>
                                </Form>


                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <RegisterOtpModal
                show={OtpModal}
                onHide={() => {
                    setOtpModal(false);
                }}
                email={EmailAddress}
                doAfterOTP={doAfterOTP}
                LocalizationLabelsArray={LocalizationLabelsArray}
            />
            <RegisterSuccessModal
                show={SuccessModal}
                onHide={() => {
                    setSuccessModal(false);
                }}
                LocalizationLabelsArray={LocalizationLabelsArray}
                mobile={SupportMobile}
            />

        </>
    );
}

export default Signup;
